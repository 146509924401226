import { ReactNode } from 'react';
import MediaQuery from 'react-responsive';
import { SM_MAX_SIZE } from '../../const/Responsive';

interface Props {
  columnLabel: string;
  children: ReactNode;
}

const DataTableCell: React.FC<Props> = ({ children, columnLabel }) => {
  return (
    <>
      <MediaQuery maxWidth={SM_MAX_SIZE}>
        <div className="table-cell-column-label">{columnLabel}</div>
      </MediaQuery>
      {children}
    </>
  );
};

export default DataTableCell;
