import { Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Step } from '../../../model/Step';
import { MarkCompleteCheckbox } from '../StepPage';
import { Project } from '../../../model/Project';

interface Props {
  completedSteps: string[];
  project: Project;
  step: Step;
  title: string;
  activeKey?: string;
  keyNumber: string;
  selectedValue?: string;
  userIsProjectOwner?: boolean;
}

const ChecklistAccordianItemHeader: React.FC<Props> = ({
  completedSteps,
  step,
  title,
  project,
  activeKey,
  keyNumber,
  selectedValue,
  userIsProjectOwner,
}) => {
  const { t } = useTranslation();
  const stepIsCompleted = completedSteps.includes(step.id);
  const isActive = keyNumber === activeKey;

  return (
    <Panel.Heading
      className={`checklist-accordian-header${
        !stepIsCompleted ? ' dark-accordian-header' : ''
      }`}
    >
      <Panel.Title>
        <div className="title-wrapper">
          <div className="header">
            {userIsProjectOwner ? (
              <MarkCompleteCheckbox
                checkboxClassName={'mark-complete-checkbox'}
                completedSteps={completedSteps}
                project={project}
                step={step}
                hideChild={true}
              />
            ) : (
              <FontAwesomeIcon
                className={
                  stepIsCompleted ? 'complete-check-icon' : 'hidden-check-icon'
                }
                icon={faCheck}
              />
            )}
            <Panel.Toggle>
              <h4>
                {title}
                <strong>
                  {selectedValue ? ` (${step.number}${selectedValue})` : ''}
                </strong>
              </h4>
            </Panel.Toggle>
          </div>
          <Panel.Toggle
            aria-label={
              isActive ? t('checklist.contract') : t('checklist.expand')
            }
          >
            <FontAwesomeIcon
              color={!stepIsCompleted ? '#ffffff' : '#26374A'}
              icon={isActive ? faChevronUp : faChevronDown}
              size="xs"
              className={!stepIsCompleted ? 'dark-toggle-icon' : ''}
            />
          </Panel.Toggle>
        </div>
      </Panel.Title>
    </Panel.Heading>
  );
};

export default ChecklistAccordianItemHeader;
