import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from '@azure/msal-react';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { AppRoute } from '../../const';
import { StepsList } from '../../const/Steps';
import { Project } from '../../model/Project';
import { LocalizedMenuItem, LocalizedNavItem } from '../i18n';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../const/Responsive';
import { ShareButton } from '../Share';
import { useUserIsProjectOwner } from '../../hooks/useUserIsProjectOwner';

interface Props {
  project?: Project;
}

const ProjectMenu: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const userIsProjectOwner = useUserIsProjectOwner(project);

  if (!project) return null;

  const projectBaseUrl = `${t(AppRoute.Project)}/${project.id}`;

  return (
    <Navbar className="project-menu" fluid>
      <MediaQuery maxWidth={SM_MAX_SIZE}>
        <Navbar.Header>
          <div className="mobile-menu-left">
            <Navbar.Toggle>
              <FontAwesomeIcon icon={faBars} className="navbar-collapse-open" />
              <FontAwesomeIcon
                icon={faTimes}
                className="navbar-collapse-close"
              />
            </Navbar.Toggle>
            <Navbar.Brand>{t('nav.project.title')}</Navbar.Brand>
          </div>

          <div className="mobile-menu-right">
            <Navbar.Text className="project-menu-button">
              <ShareButton project={project} />
            </Navbar.Text>
          </div>
        </Navbar.Header>
      </MediaQuery>
      <Navbar.Collapse>
        <Nav>
          <LocalizedNavItem
            role="listitem"
            eventKey={1}
            to={`${projectBaseUrl}/${t(AppRoute.ProjectOverview)}`}
          >
            {t('project.menu.overview')}
          </LocalizedNavItem>

          <NavDropdown
            eventKey={2}
            noCaret={true}
            title={
              <>
                {t('project.menu.stepByStep')}
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </>
            }
            id="project-menu"
            className="project-menu-steps"
          >
            {StepsList.map((step, index) => (
              <LocalizedMenuItem
                role="listitem"
                key={`step-menu-${step.id}`}
                eventKey={Number(`2.${index + 1}`)}
                to={`${projectBaseUrl}/${t(AppRoute.Step)}/${t(step.path)}`}
              >
                {step.number}. {t(`step-${step.id}:title`)}
              </LocalizedMenuItem>
            ))}
          </NavDropdown>

          <LocalizedNavItem
            role="listitem"
            eventKey={3}
            to={`${projectBaseUrl}/${t(AppRoute.ProjectChecklist)}`}
          >
            {t('project.menu.checklist')}
          </LocalizedNavItem>
        </Nav>

        <Nav pullRight>
          {userIsProjectOwner && (
            <AuthenticatedTemplate>
              <LocalizedNavItem
                role="listitem"
                eventKey={4}
                to={`${projectBaseUrl}/${t(AppRoute.ProjectSettings)}`}
              >
                {t('project.menu.projectSettings')}
              </LocalizedNavItem>
            </AuthenticatedTemplate>
          )}
          <MediaQuery minWidth={MD_MIN_SIZE}>
            <Navbar.Text className="project-menu-button" role="listitem">
              <ShareButton project={project} />
            </Navbar.Text>
          </MediaQuery>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ProjectMenu;
