/* eslint-disable react-hooks/exhaustive-deps */
import { PageHeader } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Project } from '../../model/Project';
import { AppRoute } from '../../const';
import { ProjectSettingsForm } from '../Project';
import { CreateProjectProgress } from '.';

interface Props {
  project?: Project;
  userProjects: Project[];
}

const CreateProjectSettings: React.FC<Props> = ({ project, userProjects }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createProjectRoute = `/${t(AppRoute.CreateProject)}`;

  const createCallback = (data) => {
    history.replace(
      `${createProjectRoute}/${t(AppRoute.CreateProjectSettings)}?id=${data.id}`
    );
    history.push(
      `${createProjectRoute}/${t(AppRoute.CreateProjectIdentification)}?id=${
        data.id
      }`
    );
  };

  const updateCallback = () => {
    if (project) {
      history.push(
        `${createProjectRoute}/${t(AppRoute.CreateProjectIdentification)}?id=${
          project.id
        }`
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.createProject.title')} -{' '}
          {t('pageTitle.createProject.settings')} - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>{t('createProject.title')}</PageHeader>

      <CreateProjectProgress project={project} currentStep={1} />

      <h2>{t('createProject.settings.title')}</h2>

      <ProjectSettingsForm
        createCallback={createCallback}
        project={project}
        updateCallback={updateCallback}
        userProjects={userProjects}
      />
    </>
  );
};

export default CreateProjectSettings;
