import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Control, Controller, FieldError } from 'react-hook-form';
import FieldGroup from './FieldGroup';

interface Props {
  control: Control<any>;
  defaultValue?: string | number;
  error?: FieldError;
  formGroupClasses?: string;
  help?: string;
  id: string;
  inputProps?: any;
  label?: string;
  labelHidden?: boolean;
  onBlur?: (value: { [key: string]: string | number }) => void;
  onChange?: (value: { [key: string]: string | number }) => void;
  options: {
    disabled?: boolean;
    value: string | number | readonly string[] | undefined;
    label: string | number;
  }[];
  validationRules?: {
    [key: string]:
      | string
      | boolean
      | number
      | ((value: string | number) => boolean | string)
      | { value: string | number | boolean; message: string };
  };
}

const Select: React.FC<Props> = ({
  control,
  defaultValue,
  error,
  formGroupClasses,
  help,
  id,
  label,
  labelHidden = false,
  inputProps,
  onBlur,
  onChange,
  options = [],
  validationRules,
}) => {
  return (
    <FieldGroup
      className={formGroupClasses}
      error={error}
      help={help}
      id={id}
      label={label}
      labelHidden={labelHidden}
    >
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules}
        render={({ field, fieldState }) => (
          <FormControl
            componentClass="select"
            aria-invalid={!!error ? 'true' : 'false'}
            onBlur={() => {
              if (onBlur && !fieldState.invalid) {
                onBlur({ [field.name]: field.value });
              }
              field.onBlur();
            }}
            onChange={(event: any) => {
              field.onChange(event);

              if (onChange && !fieldState.invalid) {
                onChange({ [field.name]: event.target.value });
              }
            }}
            value={field.value}
            {...inputProps}
          >
            {options.map((option) => (
              <option
                key={`${id}-${option.value}`}
                value={option.value}
                aria-disabled={option.disabled}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </FormControl>
        )}
      />
    </FieldGroup>
  );
};

export default Select;
