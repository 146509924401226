/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step6Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  stepData?: Step6Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const AlternativeReturnAirPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_6;
  const fieldValue = `${stepId}-value`;

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({ [`steps.${stepId}`]: value });
  };

  const { control, getValues, setValue } = useForm<Step6Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig18.alt'),
          fileName: t('figures:fig18.fileName'),
          longDescription: t('figures:fig18.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.a.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.a.modal.text1`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig19.alt'),
          fileName: t('figures:fig19.fileName'),
          longDescription: t('figures:fig19.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item3`)}</li>
              </ul>
              <p>{t(`${i18nForm}.b.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item2`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item3`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item4`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item5`)}</li>
                <li
                  dangerouslySetInnerHTML={{
                    __html: t(`${i18nForm}.b.modal.list2.item6`, {
                      interpolation: { escapeValue: true },
                    }),
                  }}
                />
              </ul>
              <p>{t(`${i18nForm}.b.modal.text1`)}</p>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
        video: {
          title: t(`${i18nForm}.b.video.title`),
          href: t(`${i18nForm}.b.video.href`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('alternativeReturnAir-value', stepData?.[fieldValue] || 'A');
  }, [stepData]);

  return (
    <form noValidate>
      {displayCompact ? (
        <Row>
          <Col xs={8} md={8}>
            {userIsProjectOwner ? (
              <Select
                label={t('checklist.selectedOption')}
                id={fieldValue}
                control={control}
                options={options}
                formGroupClasses={'step-option-radio'}
                onChange={(value) => submitField(value)}
              />
            ) : (
              <div className="selected-option-text">
                <label>{selectedOption?.labelData.option?.title}</label>
                <p>{selectedOption?.labelData.option?.text}</p>
              </div>
            )}
            {additionalInfo}
          </Col>
          <Col xs={4} md={4}>
            {selectedOption && (
              <img
                src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                alt={selectedOption.labelData.img?.alt}
                className="compact-image"
              />
            )}
          </Col>
        </Row>
      ) : (
        <>
          <p>{t('step-alternativeReturnAir:description')}</p>
          <RadioGroup
            id={fieldValue}
            control={control}
            items={options}
            radioClassName="step-option-radio"
            onChange={(value) => submitField(value)}
            renderLabel={(item) => <StepOptionRadioItem item={item} />}
          />
        </>
      )}
    </form>
  );
};

export default AlternativeReturnAirPage;
