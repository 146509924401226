import {
  faBars,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';
import MediaQuery from 'react-responsive';
import { AppRoute } from '../../const';
import { LocalizedMenuItem, LocalizedNavItem } from '../i18n';
import { SM_MAX_SIZE } from '../../const/Responsive';
import { useOpenSignUpSignRedirect } from '../../hooks/useOpenSignUpSignRedirect';
import { b2cPolicies, protectedResources } from '../../authConfig';

const AppHeader: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const account = useAccount();
  const { instance } = useMsal();
  const openSignUpSignInPopup = useOpenSignUpSignRedirect();

  const handleChangePassword = async () => {
    await instance.loginRedirect({
      authority: b2cPolicies.authorities.passwordChange.authority,
      redirectUri: `/`,
      scopes: [...protectedResources.projectsApi.scopes],
      extraQueryParameters: { ui_locales: language },
    });
  };

  const handleProfileEdit = async () => {
    await instance.loginRedirect({
      authority: b2cPolicies.authorities.profileEdit.authority,
      redirectUri: `/`,
      scopes: [...protectedResources.projectsApi.scopes],
      extraQueryParameters: { ui_locales: language },
    });
  };

  const handleDeleteAccount = async () => {
    await instance.loginRedirect({
      authority: b2cPolicies.authorities.accountDelete.authority,
      redirectUri: `/`,
      scopes: [...protectedResources.projectsApi.scopes],
      extraQueryParameters: { ui_locales: language },
      prompt: 'login',
    });
  };

  return (
    <>
      <h2 className="app-name-small">{t('appTitle')}</h2>
      <Navbar inverse collapseOnSelect className="main-menu" fluid>
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <Navbar.Header>
            <div className="mobile-menu-left">
              <Navbar.Toggle aria-label="main-menu-more">
                <FontAwesomeIcon
                  icon={faBars}
                  className="navbar-collapse-open"
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="navbar-collapse-close"
                />
              </Navbar.Toggle>
              <Navbar.Brand>{t('nav.main.title')}</Navbar.Brand>
            </div>

            {/* <Nav className="mobile-menu-right">
              <LocalizedNavItem
                eventKey={3}
                to="#"
                role="listitem"
                className="mobile-help"
              >
                <FontAwesomeIcon
                  className="menu-help-icon"
                  icon={faQuestionCircle}
                />{' '}
                {t('nav.main.help')}
              </LocalizedNavItem>
            </Nav> */}
          </Navbar.Header>
        </MediaQuery>
        <Navbar.Collapse>
          <Nav>
            {account ? (
              <LocalizedNavItem
                role="listitem"
                eventKey={1}
                to={AppRoute.Dashboard}
              >
                {t('nav.main.myProjects')}
              </LocalizedNavItem>
            ) : (
              <LocalizedNavItem role="listitem" eventKey={1} to={AppRoute.Home}>
                {t('nav.main.home')}
              </LocalizedNavItem>
            )}
            <LocalizedNavItem role="listitem" eventKey={2} to={AppRoute.Videos}>
              {t('nav.main.videos')}
            </LocalizedNavItem>
          </Nav>
          <Nav pullRight>
            {/* <MediaQuery minWidth={MD_MIN_SIZE}>
              <LocalizedNavItem eventKey={3} to="#" className="menu-help">
                <FontAwesomeIcon
                  className="menu-help-icon"
                  icon={faQuestionCircle}
                />{' '}
                {t('nav.main.help')}
              </LocalizedNavItem>
            </MediaQuery> */}

            <UnauthenticatedTemplate>
              <LocalizedNavItem
                role="listitem"
                eventKey={3}
                onClick={() => openSignUpSignInPopup('signUp')}
              >
                {t('nav.main.createAccount')}
              </LocalizedNavItem>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <NavDropdown
                eventKey={3}
                noCaret={true}
                title={
                  <>
                    {t('nav.main.account')}
                    <FontAwesomeIcon icon={faChevronDown} size="xs" />
                  </>
                }
                id="account-dropdown"
                className="account-dropdown"
              >
                <LocalizedMenuItem
                  eventKey={3.1}
                  onClick={() => handleProfileEdit()}
                  className="text-right-md text-right-lg"
                >
                  {t('nav.main.accountInformation')}
                </LocalizedMenuItem>
                <LocalizedMenuItem
                  eventKey={3.2}
                  onClick={() => handleChangePassword()}
                  className="text-right-md text-right-lg"
                >
                  {t('nav.main.changePassword')}
                </LocalizedMenuItem>
                <LocalizedMenuItem
                  eventKey={3.3}
                  onClick={() => handleDeleteAccount()}
                  className="text-right-md text-right-lg"
                >
                  {t('account.accountInformation.form.deleteAccount')}
                </LocalizedMenuItem>
                <LocalizedMenuItem
                  eventKey={3.4}
                  to={AppRoute.Logout}
                  className="text-right-md text-right-lg"
                >
                  {t('nav.main.logout')}
                </LocalizedMenuItem>
              </NavDropdown>
            </AuthenticatedTemplate>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AppHeader;
