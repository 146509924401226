import clsx from 'clsx';
import React from 'react';
import { FormControl, HelpBlock } from 'react-bootstrap';
import { Control, Controller, FieldError } from 'react-hook-form';
import FieldGroup from './FieldGroup';

interface Props {
  className?: string;
  control: Control<any>;
  defaultValue?: string | number;
  error?: FieldError;
  help?: string;
  id: string;
  inputProps?: any;
  label?: string;
  labelHidden?: boolean;
  onBlur?: (value: { [key: string]: string | number }) => void;
  onChangeFirst?: (value: { [key: string]: string | number }) => void;
  suffix?: string;
  type?: 'text' | 'password' | 'number' | 'hidden';
  validationRules?: {
    [key: string]:
      | string
      | boolean
      | number
      | ((value: string | number) => boolean | string)
      | { value: string | number | boolean; message: string };
  };
}

const Input: React.FC<Props> = ({
  className,
  control,
  defaultValue,
  error,
  help,
  id,
  label,
  labelHidden = false,
  inputProps,
  onBlur,
  onChangeFirst,
  suffix,
  type = 'text',
  validationRules,
}) => {
  return (
    <FieldGroup
      className={className}
      error={error}
      help={help}
      id={id}
      label={label}
      labelHidden={labelHidden}
    >
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormControl
                type={type}
                aria-invalid={!!error ? 'true' : 'false'}
                {...field}
                onBlur={() => {
                  if (onBlur && !fieldState.invalid) {
                    onBlur({ [field.name]: field.value });
                  }
                  field.onBlur();
                }}
                onChange={(e) => {
                  onChangeFirst && onChangeFirst(field.value);
                  field.onChange(e);
                }}
                {...inputProps}
                className={clsx({
                  ...inputProps?.className,
                  'input-with-suffix': suffix,
                })}
              />
              {suffix && (
                <HelpBlock className="input-suffix">{suffix}</HelpBlock>
              )}
            </>
          );
        }}
      />
    </FieldGroup>
  );
};

export default Input;
