/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  EndSessionRequest,
  IPublicClientApplication,
} from '@azure/msal-browser';

const Logout = () => {
  const { instance } = useMsal();

  const handleLogout = (
    instance: IPublicClientApplication,
    request: EndSessionRequest
  ) => {
    instance.logoutRedirect(request).catch((e) => {
      console.error(e);
    });
  };

  const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: `/`,
  };

  useEffect(() => {
    handleLogout(instance, logoutRequest);
  }, []);

  return null;
};

export default Logout;
