/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppRoute } from '../../const';
import { LocalizedSwitch } from '../i18n';
import { Project } from '../../model/Project';
import { useUserProjects } from '../../hooks/useUserProjects';
import {
  CreateProjectSettings,
  ProjectDetails,
  ProjectIdentification,
} from '.';

const CreateProjectPage: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [project, setProject] = useState<Project | undefined>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const projectId = query.get('id');

  const userProjectsQuery = useUserProjects();
  const userProjects = userProjectsQuery.data;

  useEffect(() => {
    if (!projectId || !userProjects) return undefined;

    const userProject = userProjects.find(
      (userProject) => userProject.id === projectId
    );

    if (userProject) {
      if (userProject.isStarted) {
        history.replace(`/${t(AppRoute.Project)}/${userProject.id}`);
      } else {
        setProject(userProject);
      }
    } else {
      history.replace(`${url}/${t(AppRoute.CreateProjectSettings)}`);
    }
  }, [projectId, userProjects]);

  return (
    <div className="create-project-page">
      {(!projectId || (projectId && project)) && (
        <LocalizedSwitch nested={true}>
          <Route exact path={AppRoute.CreateProjectSettings}>
            <CreateProjectSettings
              userProjects={userProjects ?? []}
              project={project}
            />
          </Route>
          {project && (
            <Route exact path={AppRoute.CreateProjectIdentification}>
              <ProjectIdentification project={project} />
            </Route>
          )}
          {project && (
            <Route exact path={AppRoute.CreateProjectDetails}>
              <ProjectDetails project={project} />
            </Route>
          )}

          <Redirect to={`${url}/${t(AppRoute.CreateProjectSettings)}`} />
        </LocalizedSwitch>
      )}
    </div>
  );
};

export default CreateProjectPage;
