import { Step, Steps } from '../model/Step';
import { AppRoute } from '.';

export const StepsList: Step[] = [
  {
    id: Steps.STEP_1,
    number: 1,
    path: AppRoute.Step1,
  },
  {
    id: Steps.STEP_2,
    number: 2,
    path: AppRoute.Step2,
  },
  {
    id: Steps.STEP_3,
    number: 3,
    path: AppRoute.Step3,
  },
  {
    id: Steps.STEP_4,
    number: 4,
    path: AppRoute.Step4,
  },
  {
    id: Steps.STEP_5,
    number: 5,
    path: AppRoute.Step5,
  },
  {
    id: Steps.STEP_6,
    number: 6,
    path: AppRoute.Step6,
  },
  {
    id: Steps.STEP_7,
    number: 7,
    path: AppRoute.Step7,
  },
  {
    id: Steps.STEP_8,
    number: 8,
    path: AppRoute.Step8,
  },
  {
    id: Steps.STEP_9,
    number: 9,
    path: AppRoute.Step9,
  },
  {
    id: Steps.STEP_10,
    number: 10,
    path: AppRoute.Step10,
  },
  {
    id: Steps.STEP_11,
    number: 11,
    path: AppRoute.Step11,
  },
  {
    id: Steps.STEP_12,
    number: 12,
    path: AppRoute.Step12,
  },
  {
    id: Steps.STEP_13,
    number: 13,
    path: AppRoute.Step13,
  },
];
