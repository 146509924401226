import { ProjectComments } from './Comments';
import { HouseType } from './House';
import { StepData } from './Step';
import { UserRoles } from './User';

export enum BestPractices {
  CONTINUOUS_AIR_CIRCULATION = 'continuousAirCirculation',
  FRAMING_LAYOUT = 'framingLayout',
  REMOTE_TEMPERATURE_SENSING = 'remoteTemperatureSensing',
  WINDOW_SELECTION = 'windowSelection',
}

export interface Checklist {
  homeBuilder: string;
  hvacDesigner: string;
  houseId: string;
  appliedTo: string;
}

export interface Collaborator {
  name: string;
  email: string;
  role: UserRoles | undefined;
}

export interface Project {
  id: string;
  additionalInformation: { [key: string]: string };
  checklist: Checklist;
  collaborators: Collaborator[];
  comments: ProjectComments;
  completedSteps: string[];
  createdAt: Date;
  houseType?: HouseType;
  isCompleted: boolean;
  isStarted: boolean;
  name: string;
  numberOfLevels?: number;
  owner: string;
  ownerRole: UserRoles;
  situations: ProjectSituationSelections;
  steps: StepData;
  updatedAt: Date;
}

export enum ProjectSituation {
  LOW_HEATING_LOADS = 'lowHeatingLoads',
  WINDOW_SOLAR_HEAT = 'windowSolarHeat',
  WINDOW_WALL_RATIO = 'windowWallRatio',
  MORE_THAN_THREE_LEVELS = 'moreThanThreeLevels',
  BASEMENT_LEVEL_FINISHED = 'basementLevelFinished',
  OPEN_FLOOR_CONCEPT = 'openFloorConcept',
  INADEQUATE_COOLING_UPPER_FLOORS = 'inadequateCoolingUpperFloors',
  POOR_HUMIDITY_CONTROL = 'poorHumidityControl',
}

export enum ProjectStatus {
  CREATED = 'created',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export type ProjectPropositions = {
  always: { [key: string]: { [key: string]: string[] } };
  bestPractices: { [key: string]: string[] };
  options: { [key: string]: string[] };
};

export enum Propositions {
  ALTERNATIVE_AIR_RETURN = 'alternativeAirReturn',
  ALTERNATIVE_SUPPLY_OUTLET = 'alternativeSupplyOutlet',
  COMPACT_HVAC = 'compactHVAC',
  CONTINUOUS_AIR_CIRCULATION = 'continuousAirCirculation',
  COOLING_AIRFLOW = 'coolingAirflow',
  COOLING_EQUIPMENT = 'coolingEquipment',
  DETERMINE_HEATING_COOLING_CHARGES = 'determineHeatingCoolingCharges',
  DUCT_SEALING = 'ductSealing',
  FRAMING_LAYOUT = 'framingLayout',
  HEATING_EQUIPMENT = 'heatingEquipment',
  MEDIUM_HIGH_VELOCITY = 'mediumHighVelocity',
  MULTI_STAGE_COOLING = 'multiStageCooling',
  MULTI_STAGE_HEATING = 'multiStageHeating',
  TSTAT_PLACEMENT = 'tStatPlacement',
  WINDOW_SELECTION = 'windowSelection',
  ZONED_SUPPLY_DUCTS = 'zonedSupplyDucts',
  ZONED_SUPPLY_DUCTS_EQUIPEMENT = 'zonedSupplyDuctsEquipement',
}

export interface ProjectSituationSelections {
  [ProjectSituation.LOW_HEATING_LOADS]: boolean;
  [ProjectSituation.WINDOW_SOLAR_HEAT]: boolean;
  [ProjectSituation.WINDOW_WALL_RATIO]: boolean;
  [ProjectSituation.MORE_THAN_THREE_LEVELS]: boolean;
  [ProjectSituation.BASEMENT_LEVEL_FINISHED]: boolean;
  [ProjectSituation.OPEN_FLOOR_CONCEPT]: boolean;
  [ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS]: boolean;
  [ProjectSituation.POOR_HUMIDITY_CONTROL]: boolean;
}
