import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppRoute } from '../../const';
import { Project } from '../../model/Project';
import { LocalizedNavLink } from '../i18n';

interface Props {
  project?: Project;
  currentStep: 1 | 2 | 3;
}

const CreateProjectProgress: React.FC<Props> = ({ project, currentStep }) => {
  const { t } = useTranslation();

  const projectQuery = project ? `?id=${project.id}` : '';

  const stepCompletion = useMemo(() => {
    return {
      step1: Boolean(project),
      step2: Boolean(project?.numberOfLevels && project?.houseType),
      step3: Boolean(project?.isStarted),
    };
  }, [project]);

  const steps = [
    {
      number: 1,
      label: t('createProject.settings.title'),
      link: `${t(AppRoute.CreateProject)}/${t(
        AppRoute.CreateProjectSettings
      )}${projectQuery}`,
      isCompleted: stepCompletion.step1,
      isLink: stepCompletion.step1 && currentStep !== 1,
    },
    {
      number: 2,
      label: t('createProject.identification.title'),
      link: `${t(AppRoute.CreateProject)}/${t(
        AppRoute.CreateProjectIdentification
      )}${projectQuery}`,
      isCompleted: stepCompletion.step2,
      isLink: stepCompletion.step1 && currentStep !== 2,
    },
    {
      number: 3,
      label: t('createProject.details.title'),
      link: `${t(AppRoute.CreateProject)}/${t(
        AppRoute.CreateProjectDetails
      )}${projectQuery}`,
      isLink: stepCompletion.step2 && currentStep !== 3,
    },
  ];
  return (
    <Grid fluid={true}>
      <Row className="create-project-progress">
        {steps.map((step) => {
          const isCurrentStep = currentStep === step.number;
          return (
            <Col
              key={`create-project-step-${step.number}`}
              className={clsx('create-project-progress-item', {
                completed: step.isCompleted,
                current: isCurrentStep,
                unavailable:
                  !step.isCompleted && !step.isLink && !isCurrentStep,
              })}
              xs={12}
              md={4}
            >
              {step.isLink ? (
                <LocalizedNavLink
                  className={clsx({
                    completed: !!project,
                    current: isCurrentStep,
                  })}
                  to={step.link}
                >
                  {step.label}
                </LocalizedNavLink>
              ) : (
                <span>{step.label}</span>
              )}

              {step.isCompleted && <FontAwesomeIcon icon={faCheckCircle} />}
            </Col>
          );
        })}
      </Row>
    </Grid>
  );
};

export default CreateProjectProgress;
