import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Dialog } from './Dialog';

const TermsAndConditionsModal: React.FC = () => {
  const { t } = useTranslation();

  const { instance } = useMsal();
  const [termsModalOpened, setTermsModalOpened] = useState(false);

  instance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      setTermsModalOpened(true);
    }
  });

  const onClose = () => {
    setTermsModalOpened(false);
  };

  return (
    <Dialog
      noActions
      onHide={onClose}
      show={!!termsModalOpened}
      title={t('termsAndCondition.title')}
      modalProps={{
        onHide: onClose,
        className: 'terms-and-conditions-modal',
      }}
    >
      <p>{t('termsAndCondition.description')}</p>
      <div className="actions">
        <a href="https://www.nrcan.gc.ca/terms-conditions" className="sp-lk">
          {t('termsAndCondition.title')}
        </a>
      </div>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
