import { StepsList } from '../const/Steps';

export const getProjectStatus = (project, t) => {
  if (project.isCompleted) {
    return t('project.status.completed');
  }

  if (project.isStarted) {
    if (project.completedSteps?.length === StepsList.length) {
      return t('project.status.completed');
    }
    return t('project.status.inProgress', {
      checkedSteps: project.completedSteps?.length || 0,
      total: StepsList.length,
    });
  }

  return t('project.status.created');
};
