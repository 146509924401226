const Disclaimer: React.FC = ({ children }) => {
  return (
    <p>
      <em>
        <strong>{children}</strong>
      </em>
    </p>
  );
};

export default Disclaimer;
