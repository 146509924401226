import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Step8Data, Steps } from '../../../model/Step';
import { Input } from '../../common/Form';
import { VideoContent } from '../Videos';

interface Props {
  stepData?: Step8Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const DetermineHeatLostGainPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_8;

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({
      [`steps.${[stepId]}`]: {
        ...stepData,
        ...value,
      },
    });
  };

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<Step8Data>({
    defaultValues: {
      dhg: stepData?.dhg,
      dhl: stepData?.dhl,
      dhwFlowrate: stepData?.dhwFlowrate,
      inletWaterT: stepData?.inletWaterT,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('dhl', stepData?.dhl);
    setValue('dhg', stepData?.dhg);
    setValue('dhwFlowrate', stepData?.dhwFlowrate);
    setValue('inletWaterT', stepData?.inletWaterT);
  }, [stepData, setValue]);

  const i18nDescription = `step-${stepId}:description`;
  const i18nForm = `step-${stepId}:form`;
  const i18nVideo = `step-${stepId}:video`;

  const dhlInput = (
    <Input
      control={control}
      label={t(`${i18nForm}.dhl.label`)}
      type="number"
      id="dhl"
      inputProps={{ disabled: !userIsProjectOwner }}
      error={errors.dhl}
      onBlur={submitField}
      validationRules={{
        min: {
          value: 0,
          message: t('errors.project.minNumber', {
            min: 0,
          }).toString(),
        },
      }}
    />
  );

  const dhgInput = (
    <Input
      control={control}
      label={t(`${i18nForm}.dhg.label`)}
      type="number"
      id="dhg"
      inputProps={{ disabled: !userIsProjectOwner }}
      error={errors.dhg}
      onBlur={submitField}
      validationRules={{
        min: {
          value: 0,
          message: t('errors.project.minNumber', {
            min: 0,
          }).toString(),
        },
      }}
    />
  );

  const dhwFlowrateInput = (
    <Input
      control={control}
      label={t(`${i18nForm}.dhwFlowrate.label`)}
      type="number"
      id="dhwFlowrate"
      inputProps={{ disabled: !userIsProjectOwner }}
      error={errors.dhwFlowrate}
      onBlur={submitField}
      validationRules={{
        min: {
          value: 0,
          message: t('errors.project.minNumber', {
            min: 0,
          }).toString(),
        },
      }}
    />
  );

  const inletWaterTInput = (
    <Input
      control={control}
      label={t(`${i18nForm}.inletWaterT.label`)}
      type="number"
      id="inletWaterT"
      inputProps={{ disabled: !userIsProjectOwner }}
      error={errors.inletWaterT}
      onBlur={submitField}
      validationRules={{
        min: {
          value: 0,
          message: t('errors.project.minNumber', {
            min: 0,
          }).toString(),
        },
      }}
    />
  );

  const getCompactReadonlyField = (fieldName: string): JSX.Element => {
    return (
      <div className="read-only-field">
        <label>{t(`${i18nForm}.${fieldName}.label`)}</label>
        <p>{stepData ? stepData[fieldName] : ''}</p>
      </div>
    );
  };

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={8} md={8}>
            {userIsProjectOwner ? dhlInput : getCompactReadonlyField('dhl')}
            {userIsProjectOwner ? dhgInput : getCompactReadonlyField('dhg')}
            {userIsProjectOwner
              ? dhwFlowrateInput
              : getCompactReadonlyField('dhwFlowrate')}
            {userIsProjectOwner
              ? inletWaterTInput
              : getCompactReadonlyField('inletWaterT')}
            {additionalInfo}
          </Col>
          <Col xs={4} md={4}>
            <img
              src={`/assets/images/${t('figures:fig22.fileName')}`}
              alt={t('figures:fig22.alt')}
              className="compact-image"
            />
          </Col>
        </Row>
      ) : (
        <div className="determine-heat-loss-gain-page">
          <form noValidate className="determine-heat-loss-gain-form">
            <Row>
              <Col xs={12} sm={6}>
                {dhlInput}
              </Col>

              <Col xs={12} sm={6}>
                {dhgInput}
              </Col>

              <Col xs={12} sm={6}>
                {dhwFlowrateInput}
              </Col>

              <Col xs={12} sm={6}>
                {inletWaterTInput}
              </Col>
            </Row>
          </form>
          <VideoContent
            video={{
              title: t(`${i18nVideo}.title`),
              href: t(`${i18nVideo}.href`),
            }}
          />
          <p>{t(`${i18nDescription}.list1.title`)}</p>
          <ul>
            <li>{t(`${i18nDescription}.list1.item1`)}</li>
            <li>{t(`${i18nDescription}.list1.item2`)}</li>
            <li>{t(`${i18nDescription}.list1.item3`)}</li>
            <li>{t(`${i18nDescription}.list1.item4`)}</li>
          </ul>

          <p>{t(`${i18nDescription}.text1`)}</p>
          <Trans
            defaults={t(`${i18nDescription}.text2`)}
            components={{ sup: <sup /> }}
          />
          <img
            src={`/assets/images/${t('figures:fig22.fileName')}`}
            aria-labelledby="image-label-1"
            aria-describedby="image-desc-1"
          />
          <p id="image-label-1">
            <strong>{t('figures:fig22.alt')}</strong>
          </p>
          <p id="image-desc-1">
            <Trans
              defaults={t('figures:fig22.longDesc')}
              components={{ sup: <sup /> }}
            />
          </p>

          <img
            src={`/assets/images/${t('figures:8.fileName')}`}
            aria-describedby="image-desc-2"
            alt={t('figures:8.alt')}
          />
          <p id="image-desc-2">{t('figures:8.longDesc')}</p>
        </div>
      )}
    </>
  );
};

export default DetermineHeatLostGainPage;
