import {
  BestPractices,
  ProjectPropositions,
  ProjectSituation,
  Propositions,
} from '../model/Project';
import { Steps } from '../model/Step';

export const BestPracticesList = [
  {
    id: BestPractices.CONTINUOUS_AIR_CIRCULATION,
  },
  {
    id: BestPractices.REMOTE_TEMPERATURE_SENSING,
  },
  {
    id: BestPractices.FRAMING_LAYOUT,
  },
  {
    id: BestPractices.WINDOW_SELECTION,
  },
];

export const defaultSituations = {
  [ProjectSituation.LOW_HEATING_LOADS]: false,
  [ProjectSituation.WINDOW_SOLAR_HEAT]: false,
  [ProjectSituation.WINDOW_WALL_RATIO]: false,
  [ProjectSituation.MORE_THAN_THREE_LEVELS]: false,
  [ProjectSituation.BASEMENT_LEVEL_FINISHED]: false,
  [ProjectSituation.OPEN_FLOOR_CONCEPT]: false,
  [ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS]: false,
  [ProjectSituation.POOR_HUMIDITY_CONTROL]: false,
};

export const SituationList = [
  ProjectSituation.LOW_HEATING_LOADS,
  ProjectSituation.WINDOW_SOLAR_HEAT,
  ProjectSituation.WINDOW_WALL_RATIO,
  ProjectSituation.MORE_THAN_THREE_LEVELS,
  ProjectSituation.BASEMENT_LEVEL_FINISHED,
  ProjectSituation.OPEN_FLOOR_CONCEPT,
  ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
  ProjectSituation.POOR_HUMIDITY_CONTROL,
];

export const PropositionsConditions = {
  bestPractices: {
    [BestPractices.CONTINUOUS_AIR_CIRCULATION]: [
      {
        proposition: Propositions.CONTINUOUS_AIR_CIRCULATION,
        conditions: [
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.BASEMENT_LEVEL_FINISHED,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
          ProjectSituation.POOR_HUMIDITY_CONTROL,
        ],
      },
    ],
    [BestPractices.REMOTE_TEMPERATURE_SENSING]: [
      {
        proposition: Propositions.TSTAT_PLACEMENT,
        conditions: [ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS],
      },
    ],
    [BestPractices.FRAMING_LAYOUT]: [
      {
        proposition: Propositions.FRAMING_LAYOUT,
        conditions: [
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.OPEN_FLOOR_CONCEPT,
        ],
      },
    ],
  },
  options: {
    [Steps.STEP_2]: [
      {
        proposition: Propositions.ALTERNATIVE_SUPPLY_OUTLET,
        conditions: [
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_3]: [
      {
        proposition: Propositions.MEDIUM_HIGH_VELOCITY,
        conditions: [
          ProjectSituation.OPEN_FLOOR_CONCEPT,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_4]: [
      {
        proposition: Propositions.ZONED_SUPPLY_DUCTS,
        conditions: [
          ProjectSituation.WINDOW_WALL_RATIO,
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.BASEMENT_LEVEL_FINISHED,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_5]: [
      {
        proposition: Propositions.ZONED_SUPPLY_DUCTS_EQUIPEMENT,
        conditions: [
          ProjectSituation.WINDOW_WALL_RATIO,
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.BASEMENT_LEVEL_FINISHED,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_6]: [
      {
        proposition: Propositions.ALTERNATIVE_AIR_RETURN,
        conditions: [
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_7]: [
      {
        proposition: Propositions.DUCT_SEALING,
        conditions: [
          ProjectSituation.MORE_THAN_THREE_LEVELS,
          ProjectSituation.BASEMENT_LEVEL_FINISHED,
          ProjectSituation.INADEQUATE_COOLING_UPPER_FLOORS,
        ],
      },
    ],
    [Steps.STEP_8]: [
      {
        proposition: Propositions.DETERMINE_HEATING_COOLING_CHARGES,
      },
    ],
    [Steps.STEP_9]: [
      {
        proposition: Propositions.WINDOW_SELECTION,
        conditions: [
          ProjectSituation.WINDOW_SOLAR_HEAT,
          ProjectSituation.WINDOW_WALL_RATIO,
        ],
      },
      {
        proposition: Propositions.COOLING_EQUIPMENT,
        conditions: [ProjectSituation.POOR_HUMIDITY_CONTROL],
      },
    ],
    [Steps.STEP_10]: [
      {
        proposition: Propositions.COOLING_AIRFLOW,
        conditions: [ProjectSituation.POOR_HUMIDITY_CONTROL],
      },
      {
        proposition: Propositions.MULTI_STAGE_COOLING,
        conditions: [ProjectSituation.POOR_HUMIDITY_CONTROL],
      },
    ],
    [Steps.STEP_11]: [
      {
        proposition: Propositions.HEATING_EQUIPMENT,
        conditions: [ProjectSituation.LOW_HEATING_LOADS],
      },
    ],
    [Steps.STEP_12]: [
      {
        proposition: Propositions.MULTI_STAGE_HEATING,
        conditions: [
          ProjectSituation.LOW_HEATING_LOADS,
          ProjectSituation.WINDOW_SOLAR_HEAT,
        ],
      },
    ],
    [Steps.STEP_13]: [
      {
        proposition: Propositions.COMPACT_HVAC,
        conditions: [ProjectSituation.LOW_HEATING_LOADS],
      },
    ],
  },
};

export const getProjectPropositions = (situations): ProjectPropositions => {
  const propositions = {
    always: {},
    bestPractices: {},
    options: {},
  };

  for (const listType in PropositionsConditions) {
    const list = PropositionsConditions[listType];

    for (const listItemKey in list) {
      const step = list[listItemKey];
      const stepPropositions: string[] = [];
      const stepAlwaysPropositions: string[] = [];

      step.forEach((stepProposition) => {
        if (!stepProposition.conditions) {
          stepAlwaysPropositions.push(stepProposition.proposition);
        } else if (
          stepProposition.conditions.some(
            (condition) => situations && situations[condition]
          )
        ) {
          stepPropositions.push(stepProposition.proposition);
        }
      });

      propositions[listType][listItemKey] = stepPropositions;
      if (stepAlwaysPropositions && !propositions.always[listType]) {
        propositions.always[listType] = {};
      }
      propositions.always[listType][listItemKey] = stepAlwaysPropositions;
    }
  }

  return propositions;
};
