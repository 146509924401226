/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Step10Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  stepData?: Step10Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const DehumidificationStrategyPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_10;
  const fieldValue = `${stepId}-value`;

  const { control, getValues, setValue } = useForm<Step10Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
    },
    mode: 'onChange',
  });

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({ [`steps.${[stepId]}`]: value });
  };

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10A.alt'),
          fileName: t('figures:10A.fileName'),
          longDescription: t('figures:10A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>
                <Trans
                  defaults={t(`${i18nForm}.a.modal.text1`)}
                  components={{ sup: <sup /> }}
                />
              </p>
              <p>{t(`${i18nForm}.a.modal.text2`)}</p>
              <p>{t(`${i18nForm}.a.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10B.alt'),
          fileName: t('figures:10B.fileName'),
          longDescription: t('figures:10B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.text1`)}</p>
              <p>{t(`${i18nForm}.b.modal.text2`)}</p>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:10C.alt'),
          fileName: t('figures:10C.fileName'),
          longDescription: t('figures:10C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
              <p>{t(`${i18nForm}.c.modal.text2`)}</p>
              <p>
                <strong>{t(`${i18nForm}.c.modal.subtitle`)}</strong>
              </p>
              <p>{t(`${i18nForm}.c.modal.text3`)}</p>
              <p>{t(`${i18nForm}.c.modal.text4`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('dehumidificationStrategy-value', stepData?.[fieldValue] || 'A');
  }, [stepData]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <Select
                  label={t('checklist.selectedOption')}
                  id={fieldValue}
                  control={control}
                  options={options}
                  formGroupClasses={'step-option-radio'}
                  onChange={submitField}
                />
              ) : (
                <div className="selected-option-text">
                  <label>{selectedOption?.labelData.option?.title}</label>
                  <p>{selectedOption?.labelData.option?.text}</p>
                </div>
              )}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className="compact-image"
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-dehumidificationStrategy:description')}</p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
          </>
        )}
      </form>
    </>
  );
};

export default DehumidificationStrategyPage;
