import { ReactNode, useMemo } from 'react';
import { NavItem, NavItemProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props extends NavItemProps {
  children: ReactNode;
  to?: string;
}

const LocalizedNavItem: React.FC<Props> = ({
  children,
  to,
  ...navItemProps
}) => {
  const { t } = useTranslation();

  const localizedRouteKey = useMemo(() => {
    if (to) {
      return `/${t(to)}`;
    }
  }, [to, t]);

  return (
    <NavItem {...navItemProps} href={localizedRouteKey}>
      {children}
    </NavItem>
  );
};

export default LocalizedNavItem;
