import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SituationList } from '../../const/Projects';
import { Project } from '../../model/Project';
import { Checkbox } from '../common/Form';

interface Props {
  project?: Project;
  control: Control<any>;
  onChange?: (value: { [key: string]: string | number }) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  viewCheckedItemsOnly?: boolean;
}

const ProjectDetailsForm: React.FC<Props> = ({
  project,
  control,
  onChange,
  displayCompact,
  userIsProjectOwner,
  viewCheckedItemsOnly,
}) => {
  const { t } = useTranslation();

  const checkboxes = SituationList.map((situationId) => (
    <Checkbox
      key={`situation-${situationId}`}
      id={`situations.${situationId}`}
      control={control}
      disabled={!userIsProjectOwner}
      onChange={onChange}
    >
      {t(`situation.${situationId}`)}
    </Checkbox>
  ));

  const checkboxesLabel = (
    <label className="section-label">
      {t('project.details.situations.label')}
    </label>
  );

  return (
    <>
      {displayCompact ? (
        <>
          {checkboxesLabel}
          {project?.situations && viewCheckedItemsOnly
            ? SituationList.filter((situation) =>
                project?.situations[situation].valueOf()
              ).map((situation) => (
                <p key={situation}>{t(`situation.${situation}`)}</p>
              ))
            : checkboxes}
        </>
      ) : (
        <>
          {checkboxesLabel}
          <p>{t('project.details.situations.description')}</p>
          {checkboxes}
        </>
      )}
    </>
  );
};

export default ProjectDetailsForm;
