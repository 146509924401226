/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step4Data, Step5Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';
import { Disclaimer } from '../../common';

interface Props {
  stepData?: Step5Data;
  step4Data?: Step4Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const AutomaticZoningEquipmentPage: React.FC<Props> = ({
  stepData,
  step4Data,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_5;
  const fieldValue = `${stepId}-value`;
  const Step4Value = step4Data ? step4Data[`${Steps.STEP_4}-value`] : null;
  const is4ASelected = Step4Value === 'A';

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({ [`steps.${[stepId]}`]: value });
  };

  const { control, getValues, setValue } = useForm<Step5Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const formValues: Step5Data = getValues();
    if (stepData && stepData[fieldValue] !== formValues[fieldValue]) {
      setValue('automaticZoningEquipment-value', stepData[fieldValue]);
    }
  });

  const i18nForm = `step-${[stepId]}:form`;

  const options: {
    disabled?: boolean;
    label: string;
    value: string | number;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig15.alt'),
          fileName: t('figures:fig15.fileName'),
          longDescription: t('figures:fig15.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.a.modal.text`)}</p>,
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          disclaimer: is4ASelected
            ? t('step-automaticZoningEquipment:disclaimer')
            : undefined,
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: is4ASelected || !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:5B_1.alt'),
          fileName: t('figures:5B_1.fileName'),
          longDescription: t('figures:5B_1.longDesc'),
        },
        modalImg: {
          alt: t('figures:5B_2.alt'),
          fileName: t('figures:5B_2.fileName'),
          longDescription: t('figures:5B_2.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.text1`)}</p>
              <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item2`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
        video: {
          title: t(`${i18nForm}.b.video.title`),
          href: t(`${i18nForm}.b.video.href`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: is4ASelected || !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig17.alt'),
          fileName: t('figures:fig17.fileName'),
          longDescription: t('figures:fig17.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.c.modal.text2`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
        video: {
          title: t(`${i18nForm}.c.video.title`),
          href: t(`${i18nForm}.c.video.href`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('automaticZoningEquipment-value', stepData?.[fieldValue] || 'A');
  }, [stepData]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <Select
                  label={t('checklist.selectedOption')}
                  id={fieldValue}
                  inputProps={{ disabled: is4ASelected }}
                  control={control}
                  options={options}
                  formGroupClasses={'step-option-radio'}
                  onChange={submitField}
                />
              ) : (
                <div className="selected-option-text">
                  <label>{selectedOption?.labelData.option?.title}</label>
                  <p>{selectedOption?.labelData.option?.text}</p>
                </div>
              )}
              {is4ASelected && (
                <Disclaimer>
                  {t('step-automaticZoningEquipment:disclaimer')}
                </Disclaimer>
              )}

              {additionalInfo}
              <Disclaimer>{t('step-automaticZoningEquipment:note')}</Disclaimer>
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className="compact-image"
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-automaticZoningEquipment:description')}</p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
            <Disclaimer>{t('step-automaticZoningEquipment:note')}</Disclaimer>
          </>
        )}
      </form>
    </>
  );
};

export default AutomaticZoningEquipmentPage;
