import { useTranslation } from 'react-i18next';

const GCFooter = () => {
  const {
    i18n: { language },
  } = useTranslation();

  if (language === 'fr') {
    return (
      <div id="def-footer">
        <footer id="wb-info">
          <div className="landscape">
            <nav className="container wb-navcurr">
              <h2 className="wb-inv">Au sujet du gouvernement</h2>
              <ul className="list-unstyled colcount-sm-2 colcount-md-3">
                <li>
                  <a href="https://www.canada.ca/fr/contact.html">
                    Contactez-nous
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/gouvernement/min.html">
                    Ministères et organismes
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/gouvernement/fonctionpublique.html">
                    Fonction publique et force militaire
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/nouvelles.html">
                    Nouvelles
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/gouvernement/systeme/lois.html">
                    Traités, lois et règlements
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/transparence/rapports.html">
                    Rapports à l'échelle du gouvernement
                  </a>
                </li>
                <li>
                  <a href="http://pm.gc.ca/fra">Premier ministre</a>
                </li>
                <li>
                  <a href="https://www.canada.ca/fr/gouvernement/systeme.html">
                    Comment le gouvernement fonctionne
                  </a>
                </li>
                <li>
                  <a href="http://ouvert.canada.ca/">Gouvernement ouvert</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="brand">
            <div className="container">
              <div className="row">
                <nav className="col-md-10 ftr-urlt-lnk">
                  <h2 className="wb-inv">À propos du site</h2>
                  <ul>
                    <li>
                      <a href="https://www.canada.ca/fr/sociaux.html">
                        Médias sociaux
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/fr/mobile.html">
                        Applications mobiles
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/fr/nouveausite.html">
                        À propos de Canada.ca
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/fr/transparence/avis.html">
                        Avis
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/fr/transparence/confidentialite.html">
                        Confidentialité
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="col-xs-6 visible-sm visible-xs tofpg">
                  <a href="#wb-cont">
                    Haut de la page{' '}
                    <span className="glyphicon glyphicon-chevron-up"></span>
                  </a>
                </div>
                <div className="col-xs-6 col-md-2 text-right">
                  <img
                    src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                    alt="Symbole du gouvernement du Canada"
                  />
                  <meta
                    property="areaServed"
                    typeof="Country"
                    content="Canada"
                  />
                  <link
                    property="logo"
                    href="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }

  if (language === 'en') {
    return (
      <div id="def-footer">
        <footer id="wb-info">
          <div className="landscape">
            <nav className="container wb-navcurr">
              <h2 className="wb-inv">About government</h2>
              <ul className="list-unstyled colcount-sm-2 colcount-md-3">
                <li>
                  <a href="https://www.canada.ca/en/contact.html">Contact us</a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/government/dept.html">
                    Departments and agencies
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/government/publicservice.html">
                    Public service and military
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/news.html">News</a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/government/system/laws.html">
                    Treaties, laws and regulations
                  </a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/transparency/reporting.html">
                    Government-wide reporting
                  </a>
                </li>
                <li>
                  <a href="http://pm.gc.ca/eng">Prime Minister</a>
                </li>
                <li>
                  <a href="https://www.canada.ca/en/government/system.html">
                    How government works
                  </a>
                </li>
                <li>
                  <a href="http://open.canada.ca/en/">Open government</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="brand">
            <div className="container">
              <div className="row">
                <nav className="col-md-10 ftr-urlt-lnk">
                  <h2 className="wb-inv">About this site</h2>
                  <ul>
                    <li>
                      <a href="https://www.canada.ca/en/social.html">
                        Social media
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/en/mobile.html">
                        Mobile applications
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/en/newsite.html">
                        About Canada.ca
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/en/transparency/terms.html">
                        Terms and conditions
                      </a>
                    </li>
                    <li>
                      <a href="https://www.canada.ca/en/transparency/privacy.html">
                        Privacy
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="col-xs-6 visible-sm visible-xs tofpg">
                  <a href="#wb-cont">
                    Top of Page{' '}
                    <span className="glyphicon glyphicon-chevron-up"></span>
                  </a>
                </div>
                <div className="col-xs-6 col-md-2 text-right">
                  <img
                    src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                    alt="Symbol of the Government of Canada"
                  />
                  <meta
                    property="areaServed"
                    typeof="Country"
                    content="Canada"
                  />
                  <link
                    property="logo"
                    href="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
  return null;
};

export default GCFooter;
