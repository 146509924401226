import React from 'react';
import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { useOpenSignUpSignRedirect } from '../../hooks/useOpenSignUpSignRedirect';

interface Props {
  action: 'signIn' | 'signUp';
}

const LoginButton: React.FC<Props> = ({ action }) => {
  const { t } = useTranslation();
  const openSignUpSignInPopup = useOpenSignUpSignRedirect();

  return (
    <Button bsStyle="primary" onClick={() => openSignUpSignInPopup(action)}>
      {action === 'signIn' ? t('login.form.submit') : t('login.signup.action')}
    </Button>
  );
};

export default LoginButton;
