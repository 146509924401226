import 'proxy-polyfill/proxy.min.js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { msalConfig } from './authConfig';
import 'jquery';
import './axios';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizedRouter, LocalizedSwitch } from './components/i18n';
import { GCWebWrapper } from './components/GCWeb';

const queryClient = new QueryClient();
export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Suspense fallback={<></>}>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <LocalizedRouter RouterComponent={Router}>
          <LocalizedSwitch>
            <GCWebWrapper>
              <App />
            </GCWebWrapper>
          </LocalizedSwitch>
        </LocalizedRouter>
      </QueryClientProvider>
    </MsalProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
