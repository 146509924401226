// @ts-nocheck
import React from 'react';

export default class VideoInitialize extends React.Component {
  //This is to initialize the Web Experience Toolkit (WET) video player
  componentDidMount() {
    this.$el = $(this.el);
    this.$el.trigger('wb-init.wb-mltmd');
  }

  render() {
    return (
      <div>
        <figure className="wb-mltmd" ref={(el) => (this.el = el)}>
          {this.props.children}
        </figure>
      </div>
    );
  }
}
