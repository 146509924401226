import { Helmet } from 'react-helmet';
import { AppLanguage } from '../../const';
import { setLang } from '../../utils/splashUtils';

const SplashPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Language selection - Natural Resources Canada / Sélection de la langue
          - Ressources naturelles Canada
        </title>
      </Helmet>
      <div className="splash">
        <link
          rel="stylesheet"
          href="/etc/designs/canada/cdts/gcweb/release/css/cdtsfixes.css"
        />
        <div id="bg">
          <img
            src={`/etc/designs/canada/cdts/gcweb/release/assets/sp-bg-6.png`}
            alt=""
          />
        </div>
        <main>
          <div className="sp-hb">
            <div className="sp-bx col-xs-12">
              <h1 property="name" className="wb-inv">
                Canada.ca
              </h1>
              <div className="row">
                <div className="col-xs-11 col-md-10">
                  <img
                    src={`/etc/designs/canada/cdts/gcweb/release/assets/NRCan.svg`}
                    width="362"
                    alt="Natural Resources Canada / Ressources naturelles Canada"
                  />
                </div>
              </div>
              <div className="row cdts-splash">
                <section className="col-xs-6 text-right">
                  <h2>
                    Master Planning & Decision App for Natural Gas Mechanical
                    Systems
                  </h2>
                  <p>
                    <a
                      href="/"
                      className="btn btn-primary"
                      onClick={() => setLang(AppLanguage.English)}
                    >
                      English
                    </a>
                  </p>
                </section>
                <section className="col-xs-6" lang="fr">
                  <h2>
                    L’application principale de planification et décision pour
                    les systèmes mécaniques au gaz naturel
                  </h2>
                  <p>
                    <a
                      href="/"
                      className="btn btn-primary"
                      onClick={() => setLang(AppLanguage.French)}
                    >
                      Français
                    </a>
                  </p>
                </section>
              </div>
            </div>
            <div className="sp-bx-bt col-xs-12">
              <div className="row">
                <div className="col-xs-7 col-md-8">
                  <a
                    href="https://www.nrcan.gc.ca/terms-conditions"
                    className="sp-lk"
                  >
                    Terms & conditions
                  </a>{' '}
                  <span className="glyphicon glyphicon-asterisk"></span>{' '}
                  <a
                    href="https://www.rncan.gc.ca/avis"
                    className="sp-lk"
                    lang="fr"
                  >
                    Avis
                  </a>
                </div>
                <div className="col-xs-5 col-md-4 text-right mrgn-bttm-md">
                  <img
                    src={`/etc/designs/canada/cdts/gcweb/release/assets/wmms-spl.svg`}
                    width="127"
                    alt="Symbol of the Government of Canada / Symbole du gouvernement du Canada"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default SplashPage;
