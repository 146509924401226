import { ReactNode } from 'react';
import { HouseType } from './House';
import { ProjectSituationSelections } from './Project';
import { Video } from './Videos';

export enum Steps {
  STEP_1 = 'projectDetails',
  STEP_2 = 'alternativeSupplyOutlet',
  STEP_3 = 'alternativeSupplyDucts',
  STEP_4 = 'zoningSupplyDucts',
  STEP_5 = 'automaticZoningEquipment',
  STEP_6 = 'alternativeReturnAir',
  STEP_7 = 'ductSealing',
  STEP_8 = 'determineHeatLostGain',
  STEP_9 = 'sizingCoolingCapacity',
  STEP_10 = 'dehumidificationStrategy',
  STEP_11 = 'sizingHeatingEquipment',
  STEP_12 = 'stagingModulatingHeatingEquipment',
  STEP_13 = 'spaceWaterHeating',
}

export interface Step {
  id: string;
  number: number;
  path: string;
}

export interface StepOption {
  modal?: {
    content: ReactNode;
    title?: ReactNode;
  };
  img?: {
    alt: string;
    fileName: string;
    longDescription: string;
  };
  modalImg?: {
    alt: string;
    fileName: string;
    longDescription: string;
  };
  option?: {
    disclaimer?: string;
    text: string;
    title?: string;
  };
  appendElements?: (JSX.Element | false)[];
  video?: Video;
}

export interface Step1Data {
  houseType: HouseType;
  numberOfLevels: number | '';
  situations: ProjectSituationSelections;
}

export interface Step2Data {
  'alternativeSupplyOutlet-value': 'A' | 'B' | 'C';
}
export interface Step3Data {
  'alternativeSupplyDucts-value': 'A' | 'B' | 'C';
}
export interface Step4Data {
  'zoningSupplyDucts-value': 'A' | 'B' | 'C';
  zoningApproachDescription: '';
}
export interface Step5Data {
  'automaticZoningEquipment-value': 'A' | 'B' | 'C';
}
export interface Step6Data {
  'alternativeReturnAir-value': 'A' | 'B';
}
export interface Step7Data {
  'ductSealing-value': 'A' | 'B' | 'C';
}
export interface Step8Data {
  dhg?: number;
  dhl?: number;
  dhwFlowrate?: number;
  inletWaterT?: number;
}
export interface Step9Data {
  tonnage?: number | string;
  'sizingCoolingCapacity-value': 'A' | 'B' | 'C';
}
export interface Step10Data {
  'dehumidificationStrategy-value': 'A' | 'B' | 'C';
}
export interface Step11Data {
  'sizingHeatingEquipment-value': 'A' | 'B' | 'C';
}
export interface Step12Data {
  'stagingModulatingHeatingEquipment-value': 'A' | 'B' | 'C';
}
export interface Step13Data {
  heatingTechDescription: string;
  ratedDhwCapacity: string;
  ratedSpaceOutput: string;
  zoningDampersRequired: string;
  'spaceWaterHeating-value': 'A' | 'B' | 'C';
}

export interface StepData {
  [Steps.STEP_1]: Step1Data;
  [Steps.STEP_2]: Step2Data;
  [Steps.STEP_3]: Step3Data;
  [Steps.STEP_4]: Step4Data;
  [Steps.STEP_5]: Step5Data;
  [Steps.STEP_6]: Step6Data;
  [Steps.STEP_7]: Step7Data;
  [Steps.STEP_8]: Step8Data;
  [Steps.STEP_9]: Step9Data;
  [Steps.STEP_10]: Step10Data;
  [Steps.STEP_11]: Step11Data;
  [Steps.STEP_12]: Step12Data;
  [Steps.STEP_13]: Step13Data;
}
