import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface Props extends NavLinkProps {
  children: ReactNode;
  to: string;
}

const LocalizedNavLink: React.FC<Props> = ({
  children,
  to,
  ...navLinkProps
}) => {
  const { t } = useTranslation();

  const localizedRouteKey = useMemo(() => {
    return `/${t(to)}`;
  }, [to, t]);

  return (
    <NavLink {...navLinkProps} to={localizedRouteKey}>
      {children}
    </NavLink>
  );
};

export default LocalizedNavLink;
