import { ReactNode } from 'react';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  type: 'error' | 'success';
}

const ServerFeedback: React.FC<Props> = ({ children, type }) => {
  return (
    <p
      className={clsx({
        'has-error': type === 'error',
        'has-success': type === 'success',
      })}
    >
      <span className="help-block">{children}</span>
    </p>
  );
};

export default ServerFeedback;
