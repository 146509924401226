import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';

interface Props {
  className?: string;
  error?: FieldError;
  id: string;
  label?: string;
  labelHidden?: boolean;
  help?: string;
  placeholder?: string;
}

const FieldGroup: React.FC<Props> = ({
  children,
  className,
  error,
  id,
  label,
  labelHidden,
  help,
}) => {
  const validationState = useMemo(() => {
    if (error) {
      return 'error';
    }
  }, [error]);

  return (
    <FormGroup
      className={className}
      controlId={id}
      validationState={validationState}
    >
      {label && (
        <ControlLabel className={clsx({ 'sr-only': labelHidden })}>
          {label}
        </ControlLabel>
      )}
      {children}
      {!error && !!help && <HelpBlock>{help}</HelpBlock>}
      {error && <HelpBlock role="alert">{error.message}</HelpBlock>}
    </FormGroup>
  );
};

export default FieldGroup;
