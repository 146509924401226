/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step4Data, Step5Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select, TextArea } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  stepData?: Step4Data;
  step5Data?: Step5Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const ZoningSupplyDuctsPage: React.FC<Props> = ({
  stepData,
  step5Data,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_4;
  const fieldValue = `${stepId}-value`;
  const step5Value = step5Data ? step5Data[`${Steps.STEP_5}-value`] : undefined;

  const submitField = (value: { [key: string]: string | number }) => {
    const selectedValue = String(value[fieldValue]);

    if (selectedValue === 'A' && step5Value !== 'A') {
      const updatedStep5 = { [`${Steps.STEP_5}-value`]: 'A' };
      updateProject({ [`steps.${Steps.STEP_5}`]: updatedStep5 });
    }

    if (
      !Object.keys(value).includes('zoningApproachDescription') &&
      selectedValue !== 'C' &&
      stepData?.zoningApproachDescription
    ) {
      setValue('zoningApproachDescription', '');
      updateProject({
        [`steps.${[stepId]}`]: {
          ...stepData,
          ...value,
          zoningApproachDescription: '',
        },
      });
    } else {
      updateProject({
        [`steps.${[stepId]}`]: {
          ...stepData,
          ...value,
        },
      });
    }
  };

  const { control, formState, getValues, setValue } = useForm<Step4Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
      zoningApproachDescription: stepData?.zoningApproachDescription || '',
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig11.alt'),
          fileName: t('figures:fig11.fileName'),
          longDescription: t('figures:fig11.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.a.modal.text`)}</p>,
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig12.alt'),
          fileName: t('figures:fig12.fileName'),
          longDescription: t('figures:fig12.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.b.modal.text`)}</p>,
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
        video: {
          title: t(`${i18nForm}.b.video.title`),
          href: t(`${i18nForm}.b.video.href`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig13.alt'),
          fileName: t('figures:fig13.fileName'),
          longDescription: t('figures:fig13.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list2.title`)}</li>
                <ul>
                  <li>{t(`${i18nForm}.c.modal.list2.item1`)}</li>
                  <li>{t(`${i18nForm}.c.modal.list2.item2`)}</li>
                  <li>{t(`${i18nForm}.c.modal.list2.item3`)}</li>
                </ul>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('zoningSupplyDucts-value', stepData?.[fieldValue] || 'A');
  }, [stepData]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <Select
                  label={t('checklist.selectedOption')}
                  id={fieldValue}
                  control={control}
                  options={options}
                  formGroupClasses={'step-option-radio'}
                  onChange={submitField}
                />
              ) : (
                <div className="selected-option-text">
                  <label>{selectedOption?.labelData.option?.title}</label>
                  <p>{selectedOption?.labelData.option?.text}</p>
                </div>
              )}
              {stepData?.['zoningSupplyDucts-value'] === 'C' &&
                (userIsProjectOwner ? (
                  <TextArea
                    className="full-width-field"
                    control={control}
                    id="zoningApproachDescription"
                    label={t(
                      `${i18nForm}.c.field.zoningApproachDescription.label`
                    )}
                    onBlur={submitField}
                  />
                ) : (
                  <div className="read-only-field">
                    <label>
                      {t(`${i18nForm}.c.field.zoningApproachDescription.label`)}
                    </label>
                    <p>{stepData?.zoningApproachDescription}</p>
                  </div>
                ))}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className="compact-image"
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-zoningSupplyDucts:description')}</p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />

            {stepData?.['zoningSupplyDucts-value'] === 'C' &&
              (userIsProjectOwner ? (
                <TextArea
                  className="full-width-field"
                  control={control}
                  id="zoningApproachDescription"
                  label={t(
                    `${i18nForm}.c.field.zoningApproachDescription.label`
                  )}
                  onBlur={submitField}
                  error={formState.errors.zoningApproachDescription}
                  validationRules={{
                    maxLength: {
                      value: 1000,
                      message: t('errors.common.maxLength', {
                        maxLength: 1000,
                      }),
                    },
                  }}
                />
              ) : (
                <div className="read-only-field">
                  <label>
                    {t(`${i18nForm}.c.field.zoningApproachDescription.label`)}
                  </label>
                  <p>{stepData?.zoningApproachDescription}</p>
                </div>
              ))}
          </>
        )}
      </form>
    </>
  );
};

export default ZoningSupplyDuctsPage;
