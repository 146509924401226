/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step9Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Input, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  stepData?: Step9Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const SizingCoolingCapacityPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_9;
  const fieldValue = `${stepId}-value`;

  const { control, getValues, setValue, formState } = useForm<Step9Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
      tonnage: stepData?.tonnage || '',
    },
    mode: 'onChange',
  });

  const submitField = (value: { [key: string]: string | number }) => {
    let obj = value;
    if (
      obj.hasOwnProperty('tonnage') &&
      stepData &&
      stepData[fieldValue] === 'C'
    ) {
      obj = {
        [fieldValue]: 'C',
        tonnage: value.tonnage,
      };
    }
    updateProject({ [`steps.${[stepId]}`]: obj });
  };

  const i18nForm = `step-${stepId}:form`;

  const tonnageInput = getValues()[fieldValue] === 'C' && (
    <Input
      id="tonnage"
      control={control}
      label={t(`${i18nForm}.tonnage.label`)}
      onBlur={submitField}
      error={formState.errors.tonnage}
      inputProps={{ disabled: !userIsProjectOwner }}
      validationRules={{
        min: {
          value: 2,
          message: t('errors.project.minNumber', {
            min: 2,
          }).toString(),
        },
        max: {
          value: 5,
          message: t('errors.project.maxNumber', {
            max: 5,
          }).toString(),
        },
        validate: (value) => {
          const num = Number(value);
          if (typeof num !== 'number' || isNaN(num)) {
            return t('errors.common.notInteger').toString();
          }
          return true;
        },
      }}
    />
  );

  const stepTable = (
    <>
      <p>
        <strong>{t(`${i18nForm}.table.description`)}</strong>
      </p>
      <Table>
        <thead>
          <tr>
            <th>{t(`${i18nForm}.table.A1`)}</th>
            <th>{t(`${i18nForm}.table.B1`)}</th>
            <th>{t(`${i18nForm}.table.C1`)}</th>
            <th>{t(`${i18nForm}.table.D1`)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t(`${i18nForm}.table.A2`)}</td>
            <td>{t(`${i18nForm}.table.B2`)}</td>
            <td>{t(`${i18nForm}.table.C2`)}</td>
            <td>{t(`${i18nForm}.table.D2`)}</td>
          </tr>
          <tr>
            <td>{t(`${i18nForm}.table.A3`)}</td>
            <td>{t(`${i18nForm}.table.B3`)}</td>
            <td>{t(`${i18nForm}.table.C3`)}</td>
            <td>{t(`${i18nForm}.table.D3`)}</td>
          </tr>
          <tr>
            <td>{t(`${i18nForm}.table.A4`)}</td>
            <td>{t(`${i18nForm}.table.B4`)}</td>
            <td>{t(`${i18nForm}.table.C4`)}</td>
            <td>{t(`${i18nForm}.table.D4`)}</td>
          </tr>
          <tr>
            <td>{t(`${i18nForm}.table.A5`)}</td>
            <td>{t(`${i18nForm}.table.B5`)}</td>
            <td>{t(`${i18nForm}.table.C5`)}</td>
            <td>{t(`${i18nForm}.table.D5`)}</td>
          </tr>
          <tr>
            <td>{t(`${i18nForm}.table.A6`)}</td>
            <td>{t(`${i18nForm}.table.B6`)}</td>
            <td>{t(`${i18nForm}.table.C6`)}</td>
            <td>{t(`${i18nForm}.table.D6`)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:fig23.alt'),
          fileName: t('figures:fig23.fileName'),
          longDescription: t('figures:fig23.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.text1`)}</p>
              <p>{t(`${i18nForm}.a.modal.text2`)}</p>
              {stepTable}
              <p>{t(`${i18nForm}.a.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:9B.alt'),
          fileName: t('figures:9B.fileName'),
          longDescription: t('figures:9B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.text1`)}</p>
              <p>{t(`${i18nForm}.b.modal.text2`)}</p>
              {stepTable}
              <p>{t(`${i18nForm}.b.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:9C.alt'),
          fileName: t('figures:9C.fileName'),
          longDescription: t('figures:9C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
              <p>{t(`${i18nForm}.c.modal.text2`)}</p>
              {stepTable}
              <p>{t(`${i18nForm}.c.modal.text3`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
        appendElements: [tonnageInput],
      },
    },
    {
      label: `${t(`${i18nForm}.noAC.option.text`)}`,
      value: 'NO_AC',
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.noAC.option.text`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('sizingCoolingCapacity-value', stepData?.[fieldValue] || 'A');
    setValue('tonnage', stepData?.tonnage);
  }, [stepData]);

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <>
                  <Select
                    label={t('checklist.selectedOption')}
                    id={fieldValue}
                    control={control}
                    options={options}
                    formGroupClasses={'step-option-radio'}
                    onChange={submitField}
                  />
                  {tonnageInput}
                </>
              ) : (
                <>
                  <div className="selected-option-text">
                    <label>{selectedOption?.labelData.option?.title}</label>
                    <p>{selectedOption?.labelData.option?.text}</p>
                  </div>
                  {stepData?.['sizingCoolingCapacity-value'] === 'C' && (
                    <div className="read-only-field">
                      <label>{t(`${i18nForm}.tonnage.label`)}</label>
                      <p>{stepData?.tonnage}</p>
                    </div>
                  )}
                </>
              )}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && selectedOption.labelData.img && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className="compact-image"
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-sizingCoolingCapacity:description')}</p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
          </>
        )}
      </form>
    </>
  );
};

export default SizingCoolingCapacityPage;
