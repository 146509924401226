import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Project } from '../../model/Project';
import { ShareDialog } from '.';

interface Props {
  project: Project;
}

const ShareButton: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <>
      <Button
        bsStyle="primary"
        bsSize="sm"
        className="btn-icon-left"
        onClick={() => setShowShareModal(true)}
      >
        <FontAwesomeIcon icon={faShareAlt} />
        {t('project.menu.share')}
      </Button>

      <ShareDialog
        project={project}
        show={showShareModal}
        setShow={setShowShareModal}
      />
    </>
  );
};

export default ShareButton;
