import { useTranslation } from 'react-i18next';

const GCPreFooter = () => {
  const {
    i18n: { language },
  } = useTranslation();

  switch (language) {
    case 'fr': {
      return (
        <div id="def-preFooter">
          <div className="pagedetails">
            <div className="row">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <a
                  href="https://www.canada.ca/fr/signaler-probleme.html"
                  className="btn btn-default text-center"
                >
                  Signaler un problème sur cette page
                </a>
              </div>
            </div>
            <div>
              <dl id="wb-dtmd">
                <dt>Date de modification: </dt>
                <dd>
                  <time property="dateModified">2021-06-15</time>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    case 'en': {
      return (
        <div id="def-preFooter">
          <div className="pagedetails">
            <div className="row">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <a
                  href="https://www.canada.ca/en/report-problem.html"
                  className="btn btn-default text-center"
                >
                  Report a problem on this page
                </a>
              </div>
            </div>
            <dl id="wb-dtmd">
              <dt>Date modified: </dt>
              <dd>
                <time property="dateModified">2021-06-15</time>
              </dd>
            </dl>
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default GCPreFooter;
