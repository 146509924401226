import { Video } from '../../../model/Videos';
import VideoInitialize from './VideoInitialize';

interface Props {
  video: Video;
}

const VideoContent: React.FC<Props> = ({ video }) => {
  return (
    <VideoInitialize>
      <video title={video.title}>
        <source type="video/youtube" src={video.href} />
      </video>
    </VideoInitialize>
  );
};

export default VideoContent;
