import { useState } from 'react';
import { Panel, PanelGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useUserIsProjectOwner } from '../../../hooks/useUserIsProjectOwner';
import { Project } from '../../../model/Project';
import { AppRoute } from '../../../const';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import { getChecklistItems } from './ChecklistItems';
import { ChecklistAccordianItemHeader } from './';

interface Props {
  project: Project;
}

const ChecklistAccordian: React.FC<Props> = ({ project }) => {
  const projectSteps = project.steps;
  const [activeKey, setActiveKey] = useState('');
  const userIsProjectOwner = useUserIsProjectOwner(project);
  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  const { t } = useTranslation();

  const history = useHistory();

  const updateProject = (value: {
    [key: string]: string | number | boolean;
  }) => {
    if (userIsProjectOwner) {
      partialUpdateProjectMutation.mutate([
        { op: 'add', path: '/updatedAt', value: new Date() },
        {
          op: 'add',
          path: `/${Object.keys(value)[0].replace('.', '/')}`,
          value: value[Object.keys(value)[0]],
        },
      ]);
    }
  };

  const checklistItems = getChecklistItems(
    project,
    updateProject,
    userIsProjectOwner
  );

  return (
    <>
      {checklistItems.map((stepObj, i) => {
        return (
          <div key={i}>
            <h3>{stepObj.groupTitle}</h3>
            <br />
            <PanelGroup
              id={`accordian-group-${i}`}
              accordion
              activeKey={activeKey}
              onSelect={(key) => {
                setActiveKey(key);
              }}
            >
              {stepObj.steps.map((step, j) => {
                const keyVal = String(i) + String(j);
                const stepId = step.step.id;
                const selectedVal =
                  (projectSteps &&
                    projectSteps[stepId] &&
                    projectSteps[stepId][`${stepId}-value`]) ||
                  '';
                return (
                  <Panel key={keyVal} eventKey={keyVal}>
                    <ChecklistAccordianItemHeader
                      title={step.title}
                      completedSteps={project.completedSteps}
                      step={step.step}
                      project={project}
                      activeKey={activeKey}
                      keyNumber={keyVal}
                      selectedValue={selectedVal}
                      userIsProjectOwner={userIsProjectOwner}
                    />
                    <Panel.Collapse>
                      <Panel.Body>
                        {step.component}
                        <br />
                        <Button
                          aria-label={t('overview.options.viewButtonAria', {
                            stepNumber: step.step.number,
                            viewLabel: t(`step-${step.step.id}:title`),
                          })}
                          type="button"
                          onClick={() => {
                            const url = `/${t(AppRoute.Project)}/${
                              project.id
                            }/${t(AppRoute.Step)}/${t(step.step.path)}`;
                            history.push(url);
                          }}
                        >
                          {t('overview.options.viewButton', {
                            stepNumber: step.step.number,
                          })}
                        </Button>
                      </Panel.Body>
                    </Panel.Collapse>
                  </Panel>
                );
              })}
            </PanelGroup>
          </div>
        );
      })}
    </>
  );
};

export default ChecklistAccordian;
