import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PageComment } from '../../../model/Comments';

interface Props {
  pageComments: PageComment[];
}

const PreviousComments: React.FC<Props> = ({ pageComments }) => {
  const { t } = useTranslation();
  return (
    <div className="comments">
      {!!pageComments.length && (
        <h2 className="h4">{t('comment.previousComments')}</h2>
      )}
      {pageComments
        .sort((a, b) =>
          moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1
        )
        .map((comment, index) => (
          <div key={`comment-${index}`} className="comment">
            <div className="comment-meta">
              <div>{moment(comment.createdAt).format('LL')}</div>
              <div>
                {t('comment.by')} {comment.userName}
              </div>
            </div>
            {comment.text}
          </div>
        ))}
    </div>
  );
};

export default PreviousComments;
