import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectStatus } from '../../model/Project';
import Select from '../common/Form/Select';

interface Props {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
}

const ProjectFilters: React.FC<Props> = ({ filter, setFilter }) => {
  const { control, watch } = useForm({
    defaultValues: { projectStatusFilter: filter },
  });
  const { t } = useTranslation();

  const filterWatch = watch('projectStatusFilter');

  useEffect(() => {
    setFilter(filterWatch);
  }, [filterWatch, setFilter]);

  const filterOptions = [
    { label: t('project.filter.items.all'), value: '' },
    { label: t('project.filter.items.created'), value: ProjectStatus.CREATED },
    {
      label: t('project.filter.items.inProgress'),
      value: ProjectStatus.IN_PROGRESS,
    },
    {
      label: t('project.filter.items.completed'),
      value: ProjectStatus.COMPLETED,
    },
  ];
  return (
    <div className="project-filters">
      <label htmlFor="projectStatusFilter">{t('project.filter.label')} </label>
      <Select
        id="projectStatusFilter"
        control={control}
        options={filterOptions}
      />
    </div>
  );
};

export default ProjectFilters;
