import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Control, Controller, FieldError } from 'react-hook-form';
import FieldGroup from './FieldGroup';

interface Props {
  className?: string;
  control: Control<any>;
  defaultValue?: string | number;
  error?: FieldError;
  help?: string;
  id: string;
  inputProps?: any;
  label?: string;
  labelHidden?: boolean;
  onBlur?: (value: { [key: string]: string | number }) => void;
  rows?: number;
  validationRules?: {
    [key: string]:
      | string
      | boolean
      | number
      | ((value: string | number) => boolean | string)
      | { value: string | number | boolean; message: string };
  };
}

const TextArea: React.FC<Props> = ({
  className,
  control,
  defaultValue,
  error,
  help,
  id,
  label,
  labelHidden = false,
  inputProps,
  onBlur,
  rows = 3,
  validationRules,
}) => {
  return (
    <FieldGroup
      className={className}
      error={error}
      help={help}
      id={id}
      label={label}
      labelHidden={labelHidden}
    >
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules}
        render={({ field, fieldState }) => (
          <FormControl
            aria-invalid={!!error ? 'true' : 'false'}
            componentClass="textarea"
            {...field}
            rows={rows}
            onBlur={() => {
              if (onBlur && !fieldState.invalid) {
                onBlur({ [field.name]: field.value });
              }
              field.onBlur();
            }}
            {...inputProps}
          />
        )}
      />
    </FieldGroup>
  );
};

export default TextArea;
