/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Control, FormState, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HouseType } from '../../model/House';
import { Project } from '../../model/Project';
import { Step1Data } from '../../model/Step';
import { Input, RadioGroup, Select } from '../common/Form';

interface Props {
  project?: Project;
  control: Control<any>;
  setValue?: UseFormSetValue<Step1Data>;
  userIsProjectOwner?: boolean;
  formState: FormState<any>;
  onBlur?: (value: { [key: string]: string | number }) => void;
  displayCompact?: boolean;
  setCompactHouseTypeImage?: Function | undefined;
}

const ProjectIdentificationForm: React.FC<Props> = ({
  project,
  control,
  setValue,
  userIsProjectOwner,
  formState,
  onBlur,
  displayCompact,
  setCompactHouseTypeImage,
}) => {
  const { t } = useTranslation();

  const houseTypes = [
    {
      value: HouseType.MULTI_UNIT_TOWN,
      label: t(`houseType.${HouseType.MULTI_UNIT_TOWN}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.MULTI_UNIT_TOWN}`)}
          src="/assets/images/Step01-Identification_A-MultiUnitTown.gif"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 4 },
    },
    {
      value: HouseType.MULTI_LEVEL_SINGLE,
      label: t(`houseType.${HouseType.MULTI_LEVEL_SINGLE}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.MULTI_LEVEL_SINGLE}`)}
          src="/assets/images/Step01-Identification_B-MultiLevelSingle.gif"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 4 },
    },
    {
      value: HouseType.BUNGALOW,
      label: t(`houseType.${HouseType.BUNGALOW}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.BUNGALOW}`)}
          src="/assets/images/Step01-Identification_C-Bungalow.gif"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 4 },
    },
  ];

  useEffect(() => {
    if (setCompactHouseTypeImage) {
      const val = houseTypes.find((ht) => ht.value === project?.houseType);
      setCompactHouseTypeImage(val?.image);
    }
    if (setValue) {
      setValue('houseType', project?.houseType || HouseType.MULTI_UNIT_TOWN);
      setValue('numberOfLevels', project?.numberOfLevels || '');
    }
  }, [project]);

  const numberOfLevelsInput = (
    <Input
      id="numberOfLevels"
      type="number"
      inputProps={{ max: 4, min: 1, disabled: !userIsProjectOwner }}
      control={control}
      error={formState.errors.numberOfLevels}
      label={t('project.identification.numberOfLevels.label')}
      labelHidden={displayCompact ? false : true}
      suffix={
        !displayCompact
          ? t('project.identification.numberOfLevels.suffix')
          : undefined
      }
      onBlur={onBlur}
      validationRules={{
        required: t('errors.common.required').toString(),
        min: {
          value: 1,
          message: t('errors.project.minNumLevels', {
            min: 1,
          }).toString(),
        },
        max: {
          value: 4,
          message: t('errors.project.maxNumLevels', {
            max: 4,
          }).toString(),
        },
        validate: (value) => {
          if (!Number.isInteger(Number(value))) {
            return t('errors.common.notInteger').toString();
          }
          return true;
        },
      }}
    />
  );

  return (
    <>
      {displayCompact ? (
        <>
          {userIsProjectOwner ? (
            <>
              <Select
                id="houseType"
                control={control}
                error={formState.errors.houseType}
                options={houseTypes}
                label={t('project.identification.houseType.label')}
                onChange={onBlur}
                validationRules={{
                  required: t('errors.common.required').toString(),
                }}
              />
              {numberOfLevelsInput}
            </>
          ) : (
            <>
              <div className="selected-option-text">
                <label>{t('project.identification.houseType.label')}</label>
                <p>{t(`houseType.${project?.houseType}`)}</p>
              </div>
              <div className="read-only-field">
                <label>
                  {t('project.identification.numberOfLevels.label')}
                </label>
                <p>{project?.numberOfLevels || ''}</p>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <label className="section-label">
            {t('project.identification.houseType.label')}
          </label>
          <p>{t('project.identification.houseType.description')}</p>
          <RadioGroup
            id="houseType"
            control={control}
            error={formState.errors.houseType}
            items={houseTypes}
            onChange={onBlur}
            validationRules={{
              required: t('errors.common.required').toString(),
            }}
          />
          {numberOfLevelsInput}
          <p>{t('project.identification.numberOfLevels.description')}</p>
        </>
      )}
    </>
  );
};

export default ProjectIdentificationForm;
