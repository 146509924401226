import { Dispatch, SetStateAction, useMemo } from 'react';
import { Checkbox as BsCheckbox, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAccount } from '@azure/msal-react';
import { AppRoute } from '../../const';
import { Project } from '../../model/Project';
import { Steps } from '../../model/Step';
import { Dialog } from '../common/Dialog';
import { Checkbox, TextArea } from '../common/Form';
import { logShare } from '../../api/projects';

interface Props {
  project: Project;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
}

interface FormValues {
  collaborators: number[];
  routes: { [key: string]: boolean };
  message: string;
}

const defaultFormValues = {
  collaborators: [],
  routes: {
    overview: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    step8: false,
    step9: false,
    step10: false,
    step11: false,
    step12: false,
    step13: false,
    checklist: false,
  },
  message: '',
};

const ShareDialog: React.FC<Props> = ({ project, setShow, show }) => {
  const { t } = useTranslation();
  const account = useAccount();

  const projectBaseUrl = `${window.location.origin}/${t(AppRoute.Project)}/${
    project.id
  }`;

  const links = useMemo(
    () => [
      { id: 'overview', route: t(AppRoute.ProjectOverview) },
      { id: 'step1', route: `${t(AppRoute.Step)}/${t(AppRoute.Step1)}` },
      { id: 'step2', route: `${t(AppRoute.Step)}/${t(AppRoute.Step2)}` },
      { id: 'step3', route: `${t(AppRoute.Step)}/${t(AppRoute.Step3)}` },
      { id: 'step4', route: `${t(AppRoute.Step)}/${t(AppRoute.Step4)}` },
      { id: 'step5', route: `${t(AppRoute.Step)}/${t(AppRoute.Step5)}` },
      { id: 'step6', route: `${t(AppRoute.Step)}/${t(AppRoute.Step6)}` },
      { id: 'step7', route: `${t(AppRoute.Step)}/${t(AppRoute.Step7)}` },
      { id: 'step8', route: `${t(AppRoute.Step)}/${t(AppRoute.Step8)}` },
      { id: 'step9', route: `${t(AppRoute.Step)}/${t(AppRoute.Step9)}` },
      { id: 'step10', route: `${t(AppRoute.Step)}/${t(AppRoute.Step10)}` },
      { id: 'step11', route: `${t(AppRoute.Step)}/${t(AppRoute.Step11)}` },
      { id: 'step12', route: `${t(AppRoute.Step)}/${t(AppRoute.Step12)}` },
      { id: 'step13', route: `${t(AppRoute.Step)}/${t(AppRoute.Step13)}` },
      { id: 'checklist', route: t(AppRoute.ProjectChecklist) },
    ],
    [t]
  );

  const { control, setValue, reset, watch } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });

  const emailSubject = t('share.email.subject', { projectName: project.name });

  const collaboratorWatch = watch('collaborators');
  const linksWatch = watch('routes');
  const messageWatch = watch('message');

  const onCollaboratorChange = (e) => {
    const collaboratorIndex = Number(
      e.target.name.replace('collaborator-', '')
    );
    if (e.target.checked) {
      setValue('collaborators', [...collaboratorWatch, collaboratorIndex]);
    } else {
      setValue(
        'collaborators',
        collaboratorWatch.filter(
          (collaboratorEmail) => collaboratorEmail !== collaboratorIndex
        )
      );
    }
  };

  const linksText = useMemo(() => {
    if (Object.values(linksWatch).some((link) => link === true)) {
      return links
        .map((link) =>
          linksWatch[link.id] ? `${projectBaseUrl}/${link.route}` : null
        )
        .filter((x) => x)
        .join('\n\n');
    }

    return `${projectBaseUrl}/${t(AppRoute.ProjectChecklist)}`;
  }, [linksWatch, links, projectBaseUrl, t]);

  const collaboratorsEmail = useMemo(() => {
    return collaboratorWatch.map(
      (collaboratorIndex) => project.collaborators[collaboratorIndex]?.email
    );
  }, [collaboratorWatch, project.collaborators]);

  const emailBody = useMemo(() => {
    const greeting =
      account && account.name
        ? `${t('share.email.greetingWithProjectOwner', {
            projectName: project.name,
            projectOwner: account.name,
          })}`
        : `${t('share.email.greeting', {
            projectName: project.name,
          })}`;
    return encodeURIComponent(
      `${greeting}${
        messageWatch
          ? `___________________\n\n${messageWatch}\n\n___________________\n\n`
          : ''
      }${t('share.email.linksLabel', {
        projectName: project.name,
      })}${linksText}
      `
    );
  }, [messageWatch, linksText, project.name, t, account]);

  const generateStepLinkCheckbox = (number, stepRoute) => {
    return (
      <Checkbox control={control} id={stepRoute}>
        {t('share.modal.links.step', {
          stepNumber: number,
          stepText: t(`step-${Steps[`STEP_${number}`]}:title`),
        })}
      </Checkbox>
    );
  };

  const onClose = () => {
    setTimeout(() => {
      setShow(false);
      reset(defaultFormValues);
    }, 0);
  };

  const onShare = () => {
    logShare(project.id, project.name, collaboratorsEmail);
    setTimeout(() => {
      setShow(false);
      reset(defaultFormValues);
    }, 0);
  };

  return (
    <Dialog
      onHide={() => onClose()}
      show={show}
      title={t('share.modal.title')}
      closeLabel={t('common.cancel')}
      modalProps={{ onHide: onClose, bsSize: 'lg', className: 'share-modal' }}
      actionBtns={[
        {
          label: t('share.modal.action.email'),
          href: `mailto:${collaboratorsEmail.join(
            ';'
          )}?body=${emailBody}&subject=${emailSubject}`,
          onClick: onShare,
          target: 'blank',
        },
        {
          label: t('share.modal.action.gmail'),
          href: `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${collaboratorsEmail.join(
            ';'
          )}&su=${emailSubject}&body=${emailBody}`,
          onClick: onShare,
          target: 'blank',
        },
        {
          label: t('share.modal.action.yahoo'),
          href: `https://compose.mail.yahoo.com/?body=${emailBody}&to=${collaboratorsEmail.join(
            ','
          )}&subject=${emailSubject}`,
          onClick: onShare,
          target: 'blank',
        },
      ]}
    >
      <p>{t('share.modal.description')}</p>

      <Form noValidate>
        <h2>{t('share.modal.collaborators.title')}</h2>
        <p>{t('share.modal.collaborators.description')}</p>
        {project.collaborators.map((collaborator, index) => (
          <BsCheckbox
            key={`${index}-${collaborator.email}`}
            name={`collaborator-${index}`}
            onChange={(e) => onCollaboratorChange(e)}
          >
            {collaborator.name} ({t(`user.role.${collaborator.role}`)})
          </BsCheckbox>
        ))}

        <h2>{t('share.modal.message.title')}</h2>
        <p>{t('share.modal.message.description')}</p>
        <TextArea
          className="full-width-field"
          control={control}
          id="message"
          label={t('share.modal.message.field.label')}
        />

        <h2>{t('share.modal.links.title')}</h2>
        <p>{t('share.modal.links.description')}</p>
        <Row>
          <Col xs={12} md={6}>
            <Checkbox control={control} id="routes.overview">
              {t('project.menu.overview')}
            </Checkbox>

            {generateStepLinkCheckbox(1, 'routes.step1')}
            {generateStepLinkCheckbox(2, 'routes.step2')}
            {generateStepLinkCheckbox(3, 'routes.step3')}
            {generateStepLinkCheckbox(4, 'routes.step4')}
            {generateStepLinkCheckbox(5, 'routes.step5')}
            {generateStepLinkCheckbox(6, 'routes.step6')}
            {generateStepLinkCheckbox(7, 'routes.step7')}
          </Col>
          <Col xs={12} md={6}>
            {generateStepLinkCheckbox(8, 'routes.step8')}
            {generateStepLinkCheckbox(9, 'routes.step9')}
            {generateStepLinkCheckbox(10, 'routes.step10')}
            {generateStepLinkCheckbox(11, 'routes.step11')}
            {generateStepLinkCheckbox(12, 'routes.step12')}
            {generateStepLinkCheckbox(13, 'routes.step13')}
            <Checkbox control={control} id="routes.checklist">
              {t('project.menu.checklist')}
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Dialog>
  );
};

export default ShareDialog;
