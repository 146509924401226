import { Dispatch, SetStateAction } from 'react';
import { Video } from '../../../model/Videos';
import Dialog from '../../common/Dialog/Dialog';
import VideoContent from './VideoContent';

interface Props {
  video: Video;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
}

const VideoModal: React.FC<Props> = ({ video, setShow, show }) => {
  return (
    <Dialog
      onHide={() => setShow(false)}
      modalProps={{
        backdrop: true,
        bsSize: 'lg',
        className: 'video-modal',
        onHide: () => setShow(false),
      }}
      show={show}
      title={video.title}
    >
      <VideoContent video={video} />
    </Dialog>
  );
};

export default VideoModal;
