import { ReactNode, useMemo } from 'react';
import { MenuItem, MenuItemProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props extends MenuItemProps {
  children: ReactNode;
  to?: string;
}

const LocalizedMenuItem: React.FC<Props> = ({
  children,
  to,
  ...navItemProps
}) => {
  const { t } = useTranslation();

  const localizedRouteKey = useMemo(() => {
    if (to) {
      return `/${t(to)}`;
    }
  }, [to, t]);

  return (
    <MenuItem {...navItemProps} href={localizedRouteKey}>
      {children}
    </MenuItem>
  );
};

export default LocalizedMenuItem;
