import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { b2cPolicies, protectedResources } from '../authConfig';

export const useOpenSignUpSignRedirect = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { instance } = useMsal();

  return async (action: 'signIn' | 'signUp') => {
    const loginRequest: RedirectRequest = {
      authority:
        action === 'signIn'
          ? b2cPolicies.authorities.signUpSignIn.authority
          : b2cPolicies.authorities.signUp.authority,
      redirectUri: `/`,
      scopes: [...protectedResources.projectsApi.scopes],
      extraQueryParameters: { ui_locales: language },
    };

    try {
      await instance.loginRedirect(loginRequest);
    } catch (e) {
      console.error(e);
    }
  };
};
