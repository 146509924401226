import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Select from '../common/Form/Select';

interface Props {
  sortDirection: string | number;
  sortField: string | number;
}

const ProjectMobileSort: React.FC<Props> = ({ sortDirection, sortField }) => {
  const { control } = useForm({
    defaultValues: { sortField, sortDirection },
  });
  const { t } = useTranslation();

  const fieldOptions = [
    {
      label: `${t('project.table.name.label')} (${t('sort.asc')})`,
      value: 'name-asc',
    },
    {
      label: `${t('project.table.name.label')} (${t('sort.desc')})`,
      value: 'name-desc',
    },
    {
      label: `${t('project.table.createdAt.label')} (${t('sort.asc')})`,
      value: 'createdAt-asc',
    },
    {
      label: `${t('project.table.createdAt.label')} (${t('sort.desc')})`,
      value: 'createdAt-desc',
    },
    {
      label: `${t('project.table.updatedAt.label')} (${t('sort.asc')})`,
      value: 'updatedAt-asc',
    },
    {
      label: `${t('project.table.updatedAt.label')} (${t('sort.desc')})`,
      value: 'updatedAt-desc',
    },
    {
      label: `${t('project.table.status.label')} (${t('sort.asc')})`,
      value: 'status-asc',
    },
    {
      label: `${t('project.table.status.label')} (${t('sort.desc')})`,
      value: 'status-desc',
    },
  ];

  const sort = (value) => {
    const [selectedSortField, selectedSortDirection] =
      value.sortField.split('-');

    $(`#column-${selectedSortField}`).click();

    // Force a reclick to get proper sort direction
    if (
      sortField !== selectedSortField &&
      selectedSortDirection !== sortDirection
    ) {
      setTimeout(() => {
        $(`#column-${selectedSortField}`).click();
      });
    }
  };

  return (
    <div className="project-filters">
      <div>
        <label htmlFor="sortField">{t('project.sort.field.label')}</label>
        <Select
          id="sortField"
          control={control}
          options={fieldOptions}
          onChange={sort}
        />
      </div>
    </div>
  );
};

export default ProjectMobileSort;
