import { ReactNode, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonProps, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import $ from 'jquery';

interface Props {
  actionBtns?: ButtonProps[];
  children?: ReactNode;
  closeLabel?: string;
  modalProps?: ModalProps;
  noActions?: boolean;
  onHide: () => void;
  show: boolean;
  title?: string | ReactNode;
}

const Dialog: React.FC<Props> = ({
  actionBtns,
  children,
  closeLabel,
  modalProps,
  noActions,
  onHide,
  show,
  title,
}) => {
  const modalEl = useRef<Modal | null>(null);
  useEffect(() => {
    if (show) {
      (ReactDOM.findDOMNode(modalEl.current) as HTMLElement)?.focus();
    }
  }, [show]);
  const { t } = useTranslation();

  useEffect(() => {
    // For accessibility reasons, because Bootstrap 3 is not great
    const dialogElements = $('[role=dialog][aria-hidden=true]');
    dialogElements.attr({
      'aria-modal': 'true',
      tabindex: '-1',
    });
    dialogElements.removeAttr('aria-hidden');
    $('.modal-backdrop').attr('tabindex', '-1');
    $('#root').removeAttr('aria-hidden');
  }, []);

  return (
    <Modal ref={modalEl} onHide={onHide} show={show} {...modalProps}>
      {title && (
        <Modal.Header
          closeButton={true}
          onHide={onHide}
          closeLabel={t('common.close')}
        >
          <h1 className="modal-title">{title}</h1>
        </Modal.Header>
      )}

      <Modal.Body>{children}</Modal.Body>

      {!noActions && (
        <Modal.Footer
          className={clsx({
            'modal-footer-with-actions': !!actionBtns?.length,
          })}
        >
          {actionBtns &&
            actionBtns.map((button) => (
              <Button
                key={`modal-button-${button.label}`}
                bsSize="small"
                bsStyle="primary"
                {...button}
              >
                {button.label}
              </Button>
            ))}

          <Button bsSize="small" onClick={onHide}>
            {closeLabel || t('common.close')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default Dialog;
