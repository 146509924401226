import { useState } from 'react';
import { Button, ButtonGroup, Col, Row, Table } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { BestPracticesList } from '../../const/Projects';
import { OptionDetailsModal } from './StepPage';
import { VideoModal } from './Videos';
import { ProjectOverviewProposition } from '.';

interface Props {
  propositions: { [key: string]: string[] | undefined };
}

const ProjectOverviewBestPractices: React.FC<Props> = ({ propositions }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [idToShow, setIdToShow] = useState('');

  const initiateModal = (id) => {
    setIdToShow(id);
    setShowModal(true);
  };

  const initiateVideoModal = (id) => {
    setIdToShow(id);
    setShowVideoModal(true);
  };

  const bestPractices = [
    {
      id: BestPracticesList[0].id,
      info: {
        modal: {
          content: (
            <>
              <p>
                <b>{t('bestPractices:continuousAirCirculation.modal.text1')}</b>
              </p>
              <p>{t('bestPractices:continuousAirCirculation.modal.text2')}</p>
              <ul>
                <li>
                  {t(
                    'bestPractices:continuousAirCirculation.modal.list1.item1'
                  )}
                </li>
              </ul>
              <p>
                <b>{t('bestPractices:continuousAirCirculation.modal.text3')}</b>
              </p>
              <p>{t('bestPractices:continuousAirCirculation.modal.text4')}</p>
              <p>{t('bestPractices:continuousAirCirculation.modal.text5')}</p>
              <ul>
                <li>
                  {t(
                    'bestPractices:continuousAirCirculation.modal.list2.item1'
                  )}
                </li>
              </ul>
            </>
          ),
          title: t('bestPractices:continuousAirCirculation.title'),
        },
      },
    },
    {
      id: BestPracticesList[1].id,
      info: {
        img: {
          alt: t('figures:remoteTempSensing.alt'),
          fileName: t('figures:remoteTempSensing.fileName'),
          longDescription: t('figures:remoteTempSensing.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t('bestPractices:remoteTemperatureSensing.modal.text1')}</p>
              <p>{t('bestPractices:remoteTemperatureSensing.modal.text2')}</p>
              <p>
                <b>{t('bestPractices:remoteTemperatureSensing.modal.text3')}</b>
              </p>
              <ul>
                <li>
                  <p>
                    {t(
                      'bestPractices:remoteTemperatureSensing.modal.list1.title'
                    )}
                  </p>
                </li>
                <ul>
                  <li>
                    {t(
                      'bestPractices:remoteTemperatureSensing.modal.list1.item1'
                    )}
                  </li>
                  <li>
                    {t(
                      'bestPractices:remoteTemperatureSensing.modal.list1.item2'
                    )}
                  </li>
                </ul>
              </ul>
            </>
          ),
          title: t('bestPractices:remoteTemperatureSensing.title'),
        },
      },
    },
    {
      id: BestPracticesList[2].id,
      info: {
        img: {
          alt: t('figures:framingLayout.alt'),
          fileName: t('figures:framingLayout.fileName'),
          longDescription: t('figures:framingLayout.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t('bestPractices:framingLayout.modal.text1')}</p>
              <p>{t('bestPractices:framingLayout.modal.text2')}</p>
              <p>{t('bestPractices:framingLayout.modal.text3')}</p>
              <p>{t('bestPractices:framingLayout.modal.text4')}</p>
            </>
          ),
          title: t('bestPractices:framingLayout.title'),
        },
      },
      video: {
        title: t('bestPractices:framingLayout.video.title'),
        href: t('bestPractices:framingLayout.video.href'),
      },
    },
    {
      id: BestPracticesList[3].id,
      info: {
        img: {
          alt: t('figures:fig24.alt'),
          fileName: t('figures:fig24.fileName'),
          longDescription: t('figures:fig24.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t('bestPractices:windowSelection.modal.list1.title')}</p>
              <ul>
                <li>{t('bestPractices:windowSelection.modal.list1.item1')}</li>
                <li>{t('bestPractices:windowSelection.modal.list1.item2')}</li>
                <li>{t('bestPractices:windowSelection.modal.list1.item3')}</li>
              </ul>
              <p>{t('bestPractices:windowSelection.modal.text1')}</p>
              <p>{t('bestPractices:windowSelection.modal.list2.title')}</p>
              <ul>
                <li>{t('bestPractices:windowSelection.modal.list2.item1')}</li>
                <li>{t('bestPractices:windowSelection.modal.list2.item2')}</li>
                <li>
                  <Trans
                    defaults={t(
                      'bestPractices:windowSelection.modal.list2.item3'
                    )}
                    components={{ sup: <sup /> }}
                  />
                </li>
              </ul>
              <p>
                <b>{t('bestPractices:windowSelection.modal.list3.title')}</b>
              </p>
              <ul>
                <li>{t('bestPractices:windowSelection.modal.list3.item1')}</li>
                <li>{t('bestPractices:windowSelection.modal.list3.item2')}</li>
              </ul>
              <p>
                <strong>
                  {t('bestPractices:windowSelection.modal.table.title')}
                </strong>
              </p>
              <Table>
                <thead>
                  <tr>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th1')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th2')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th3')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th4')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th5')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th6')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th7')}
                    </th>
                    <th>
                      {t('bestPractices:windowSelection.modal.table.th8')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row1.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row1.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row1.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row2.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row2.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row2.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row3.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row3.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row4.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row4.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row5.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row5.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row6.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row6.col8')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col1')}
                    </td>
                    <td>
                      {t('bestPractices:windowSelection.modal.table.row7.col2')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col3')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col4')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col5')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col6')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col7')}
                    </td>
                    <td className="text-center">
                      {t('bestPractices:windowSelection.modal.table.row7.col8')}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="table-notes">
                <p>
                  <strong>
                    {t('bestPractices:windowSelection.modal.tableNotes.title')}
                  </strong>
                </p>
                <ul>
                  <li>
                    {t('bestPractices:windowSelection.modal.tableNotes.note1')}
                  </li>
                  <li>
                    {t('bestPractices:windowSelection.modal.tableNotes.note2')}
                  </li>
                  <li>
                    {t('bestPractices:windowSelection.modal.tableNotes.note3')}
                  </li>
                </ul>
              </div>
            </>
          ),
          title: t('bestPractices:windowSelection.title'),
        },
      },
      video: {
        title: t('bestPractices:windowSelection.video.title'),
        href: t('bestPractices:windowSelection.video.href'),
      },
    },
  ];

  const bestPracticeToShow = bestPractices.find((bp) => bp.id === idToShow);

  return (
    <>
      {bestPractices.map((bestPractice) => {
        return (
          <Row
            key={`overview-best-practices-${bestPractice.id}`}
            className="overview-item"
          >
            <Col xs={12} sm={8}>
              <h3>{t(`bestPractices:${bestPractice.id}.title`)}</h3>
            </Col>
            <Col xs={12} sm={4} className="text-right text-right-not-xs">
              <ButtonGroup className="best-practices-button-group">
                {bestPractice.video && (
                  <>
                    <Button
                      aria-label={`${t('common.watchVideo')}: ${
                        bestPractice.video.title
                      }`}
                      className="btn btn-primary watch-video-button"
                      onClick={() => initiateVideoModal(bestPractice.id)}
                      bsSize="sm"
                    >
                      <FontAwesomeIcon icon={faPlayCircle} />
                      <span className="video-button-text">
                        {t('common.watchVideo')}
                      </span>
                    </Button>
                  </>
                )}
                <Button
                  aria-label={t('overview.bestPractices.viewButtonAria', {
                    viewLabel: t(
                      `bestPractices:${bestPractice.id}.viewButtonLabel`
                    ),
                  })}
                  bsSize="sm"
                  onClick={() => initiateModal(bestPractice.id)}
                >
                  {t('overview.bestPractices.viewButton')}
                </Button>
              </ButtonGroup>
              <OptionDetailsModal
                option={bestPracticeToShow?.info || bestPractices[0].info}
                show={showModal}
                setShow={setShowModal}
              />
              {bestPracticeToShow && bestPracticeToShow.video && (
                <VideoModal
                  video={bestPracticeToShow.video}
                  show={showVideoModal}
                  setShow={setShowVideoModal}
                />
              )}
            </Col>

            <Col xs={12}>
              <ProjectOverviewProposition
                id={bestPractice.id}
                propositions={propositions[bestPractice.id]}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default ProjectOverviewBestPractices;
