import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getLang } from './utils/splashUtils';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: getLang() || 'en',
    ns: [
      'bestPractices',
      'common',
      'figures',
      'license',
      'routes',
      'step-alternativeReturnAir',
      'step-alternativeSupplyDucts',
      'step-alternativeSupplyOutlet',
      'step-automaticZoningEquipment',
      'step-dehumidificationStrategy',
      'step-determineHeatLostGain',
      'step-ductSealing',
      'step-projectDetails',
      'step-sizingCoolingCapacity',
      'step-sizingHeatingEquipment',
      'step-spaceWaterHeating',
      'step-stagingModulatingHeatingEquipment',
      'step-zoningSupplyDucts',
    ],
    preload: ['en', 'fr'],
  });

export default i18n;
