import i18next from 'i18next';
import { Steps } from '../../../model/Step';
import { StepsList } from '../../../const/Steps';
import {
  ProjectDetailsPage,
  AlternativeSupplyOutletsPage,
  AlternativeSupplyDuctsPage,
  ZoningSupplyDuctsPage,
  AutomaticZoningEquipmentPage,
  AlternativeReturnAirPage,
  DuctSealingPage,
  DetermineHeatLostGainPage,
  SizingCoolingCapacityPage,
  DehumidificationStrategyPage,
  SizingOfHeatingEquipmentPage,
  StagingModulatingHeatingPage,
  SpaceAndWaterHeatingPage,
} from '../Steps';
import { AdditionalInfoInput } from '../StepPage';
import { Project } from '../../../model/Project';

export const getChecklistItems = (
  project: Project,
  updateProject: (value: { [key: string]: string | number | boolean }) => void,
  userIsProjectOwner?: boolean
) => {
  const steps = project.steps || {};

  const getAdditionalInfoinput = (step) => (
    <AdditionalInfoInput
      project={project}
      step={step}
      additionalInformation={project.additionalInformation}
      viewOnly={!userIsProjectOwner}
    />
  );

  const checklistItems = [
    {
      groupTitle: i18next.t('checklist.accordianTitle1'),
      steps: [
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 1,
          })} - ${i18next.t('project.details.title')}`,
          step: StepsList[0],
          component: (
            <ProjectDetailsPage
              key={0}
              project={project}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 2,
          })} - ${i18next.t('step-alternativeSupplyOutlet:title')}`,
          step: StepsList[1],
          component: (
            <AlternativeSupplyOutletsPage
              key={1}
              stepData={steps[Steps.STEP_2]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[1])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 3,
          })} - ${i18next.t('step-alternativeSupplyDucts:title')}`,
          step: StepsList[2],
          component: (
            <AlternativeSupplyDuctsPage
              key={2}
              stepData={steps[Steps.STEP_3]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[2])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 4,
          })} - ${i18next.t('step-zoningSupplyDucts:title')}`,
          step: StepsList[3],
          component: (
            <ZoningSupplyDuctsPage
              key={3}
              stepData={steps[Steps.STEP_4]}
              step5Data={steps[Steps.STEP_5]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[3])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 5,
          })} - ${i18next.t('step-automaticZoningEquipment:title')}`,
          step: StepsList[4],
          component: (
            <AutomaticZoningEquipmentPage
              key={4}
              stepData={steps[Steps.STEP_5]}
              step4Data={steps[Steps.STEP_4]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[4])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 6,
          })} - ${i18next.t('step-alternativeReturnAir:title')}`,
          step: StepsList[5],
          component: (
            <AlternativeReturnAirPage
              key={5}
              stepData={steps[Steps.STEP_6]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[5])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 7,
          })} - ${i18next.t('step-ductSealing:title')}`,
          step: StepsList[6],
          component: (
            <DuctSealingPage
              key={6}
              stepData={steps[Steps.STEP_7]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[6])}
            />
          ),
        },
      ],
    },
    {
      groupTitle: i18next.t('checklist.accordianTitle2'),
      steps: [
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 8,
          })} - ${i18next.t('step-determineHeatLostGain:title')}`,
          step: StepsList[7],
          component: (
            <DetermineHeatLostGainPage
              key={7}
              stepData={steps[Steps.STEP_8]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[7])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 9,
          })} - ${i18next.t('step-sizingCoolingCapacity:title')}`,
          step: StepsList[8],
          component: (
            <SizingCoolingCapacityPage
              key={8}
              stepData={steps[Steps.STEP_9]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[8])}
            />
          ),
        },
      ],
    },
    {
      groupTitle: i18next.t('checklist.accordianTitle3'),
      steps: [
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 10,
          })} - ${i18next.t('step-dehumidificationStrategy:title')}`,
          step: StepsList[9],
          component: (
            <DehumidificationStrategyPage
              key={9}
              stepData={steps[Steps.STEP_10]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[9])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 11,
          })} - ${i18next.t('step-sizingHeatingEquipment:title')}`,
          step: StepsList[10],
          component: (
            <SizingOfHeatingEquipmentPage
              key={10}
              stepData={steps[Steps.STEP_11]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[10])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 12,
          })} - ${i18next.t('step-stagingModulatingHeatingEquipment:title')}`,
          step: StepsList[11],
          component: (
            <StagingModulatingHeatingPage
              key={11}
              stepData={steps[Steps.STEP_12]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[11])}
            />
          ),
        },
        {
          title: `${i18next.t('checklist.stepNumber', {
            stepNumber: 13,
          })} - ${i18next.t('step-spaceWaterHeating:title')}`,
          step: StepsList[12],
          component: (
            <SpaceAndWaterHeatingPage
              key={12}
              stepData={steps[Steps.STEP_13]}
              updateProject={updateProject}
              displayCompact={true}
              userIsProjectOwner={userIsProjectOwner}
              additionalInfo={getAdditionalInfoinput(StepsList[12])}
            />
          ),
        },
      ],
    },
  ];
  return checklistItems;
};
