import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Checkbox as BSCheckbox } from 'react-bootstrap';
import { Control, Controller, FieldError } from 'react-hook-form';
import FieldGroup from './FieldGroup';

interface Props {
  children?: string | ReactNode;
  className?: string;
  control: Control<any>;
  defaultValue?: boolean;
  disabled?: boolean;
  error?: FieldError;
  help?: string;
  hideChild?: boolean;
  id: string;
  label?: string;
  labelHidden?: boolean;
  onChange?: (value: { [key: string]: string | number }) => void;
  validationRules?: {
    [key: string]:
      | string
      | boolean
      | number
      | ((value: number) => boolean | string);
  };
}

const Checkbox: React.FC<Props> = ({
  children,
  className,
  control,
  defaultValue,
  disabled,
  error,
  help,
  id,
  label,
  labelHidden = false,
  hideChild = false,
  onChange,
  validationRules,
}) => {
  return (
    <FieldGroup
      className={className}
      error={error}
      help={help}
      id={id}
      label={label}
      labelHidden={labelHidden}
    >
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules}
        render={({ field, fieldState }) => (
          <BSCheckbox
            type="checkbox"
            aria-label={label}
            checked={field.value}
            disabled={disabled}
            value={field.value}
            onBlur={field.onBlur}
            onChange={(event: any) => {
              field.onChange(event);

              if (onChange && !fieldState.invalid) {
                onChange({ [field.name]: event.target.checked });
              }
            }}
          >
            <span className={clsx({ 'sr-only': hideChild })}>{children}</span>
          </BSCheckbox>
        )}
      ></Controller>
    </FieldGroup>
  );
};

export default Checkbox;
