import React, { useMemo } from 'react';
import { Route, BrowserRouterProps } from 'react-router-dom';
import { SplashPage } from '../Splash';
import { getLang } from '../../utils/splashUtils';

interface Props {
  RouterComponent: React.ComponentClass<BrowserRouterProps>;
}

const LocalizedRouter: React.FC<Props> = ({ children, RouterComponent }) => {
  const isInit = useMemo(() => Boolean(getLang()), []);

  return (
    <RouterComponent>
      <Route path="/">
        {({ location }) => {
          const { pathname } = location;

          if (pathname === '/' && !isInit) {
            return <SplashPage />;
          }

          return <>{children}</>;
        }}
      </Route>
    </RouterComponent>
  );
};

export default LocalizedRouter;
