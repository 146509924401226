import { useTranslation } from 'react-i18next';
import { Project } from '../../../model/Project';
import { StepsList } from '../../../const/Steps';

interface Props {
  reference: React.MutableRefObject<null>;
  project: Project;
}

const ChecklistPrintFormat: React.FC<Props> = ({ reference, project }) => {
  const { t } = useTranslation();
  const projectSteps = project.steps;
  const situations = project.situations;
  const selectedSituationsJSX = Object.keys(situations).map((objKey, i) => {
    if (situations[objKey]) {
      return <li key={i}>{t(`situation.${objKey}`)}</li>;
    }

    return null;
  });
  const determineHeatLostGainExists =
    projectSteps && projectSteps.determineHeatLostGain;
  const generateHeader = () => {
    return (
      <>
        <a href="https://www.canada.ca/en.html" property="url">
          <img
            className="print-gov-logo"
            src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/sig-blk-en.svg"
            alt=""
            property="logo"
          />
        </a>
        <p>
          <a className="website-link" href="https://www.canada.ca/en.html">
            Canada.ca
          </a>
        </p>
        <h2 className="app-name-small">{t('appTitle')}</h2>
        <h1>{t('pageTitle.checklist', { projectName: project.name })}</h1>
        <h2>{t('checklist.title')}</h2>
        <p>{t('checklist.description')}</p>
      </>
    );
  };
  const generateChecklistFieldInfo = () => {
    return (
      <>
        {project.checklist && project.checklist.homeBuilder && (
          <>
            <label>{t('checklist.form.homeBuilder.label')}</label>
            <p className="responseText">{project.checklist.homeBuilder}</p>
          </>
        )}
        {project.checklist && project.checklist.hvacDesigner && (
          <>
            <label>{t('checklist.form.hvacDesigner.label')}</label>
            <p className="responseText">{project.checklist.hvacDesigner}</p>
          </>
        )}
        {project.checklist && project.checklist.houseId && (
          <>
            <label>{t('checklist.form.houseId.label')}</label>
            <p className="responseText">{project.checklist.houseId}</p>
          </>
        )}
        {project.checklist && project.checklist.appliedTo && (
          <>
            <label>{t('checklist.form.appliedTo.label')}</label>
            <p className="responseText">
              {t(`checklist.form.${project.checklist.appliedTo}.label`)}
            </p>
          </>
        )}
        {project.checklist &&
          (project.checklist.homeBuilder ||
            project.checklist.hvacDesigner ||
            project.checklist.houseId ||
            project.checklist.appliedTo) && (
            <p>
              <em>{t('checklist.footNote')}</em>
            </p>
          )}
      </>
    );
  };
  const generateAdditionalInfo = (label, text) => {
    return (
      <>
        <label>{label}</label>
        <p className="additional-info-text">{text}</p>
      </>
    );
  };
  const generateStep1Info = () => {
    const stepIsCompleted = project.completedSteps.some(
      (s) => s === 'projectDetails'
    );
    return (
      <div className="print-step">
        <hr />
        <h4>
          {stepIsCompleted && (
            <img
              alt=""
              className="completed-step"
              src={'/assets/images/CompletedStep.png'}
            />
          )}
          {`${t('checklist.stepNumber', { stepNumber: 1 })} - ${t(
            'project.details.title'
          )}`}
        </h4>
        <label>{t('project.identification.houseType.label')}</label>
        <p>{t(`houseType.${project.houseType}`)}</p>
        <label>{t('project.identification.numberOfLevels.label')}</label>
        <p>{project.numberOfLevels}</p>
        <label>{t('project.details.situations.label')}</label>
        <ul>{selectedSituationsJSX}</ul>
      </div>
    );
  };
  const generateStep8Info = () => {
    const stepIsCompleted = project.completedSteps.some(
      (s) => s === 'determineHeatLostGain'
    );
    return (
      <div className="print-step">
        <hr />
        <h4>
          {stepIsCompleted && (
            <img
              alt=""
              className="completed-step"
              src={'/assets/images/CompletedStep.png'}
            />
          )}
          {`${t('checklist.stepNumber', {
            stepNumber: StepsList[7].number,
          })} - ${t('step-determineHeatLostGain:title')}`}
        </h4>

        <label>{t('step-determineHeatLostGain:form.dhl.label')}</label>
        <p>
          {(determineHeatLostGainExists &&
            projectSteps.determineHeatLostGain.dhl) ||
            t('common.nonApplicable')}
        </p>
        <label>{t('step-determineHeatLostGain:form.dhg.label')}</label>
        <p>
          {(determineHeatLostGainExists &&
            projectSteps.determineHeatLostGain.dhg) ||
            t('common.nonApplicable')}
        </p>
        <label>{t('step-determineHeatLostGain:form.dhwFlowrate.label')}</label>
        <p>
          {(determineHeatLostGainExists &&
            projectSteps.determineHeatLostGain.dhwFlowrate) ||
            t('common.nonApplicable')}
        </p>
        <label>{t('step-determineHeatLostGain:form.inletWaterT.label')}</label>
        <p>
          {(determineHeatLostGainExists &&
            projectSteps.determineHeatLostGain.inletWaterT) ||
            t('common.nonApplicable')}
        </p>
        {project.additionalInformation &&
          project.additionalInformation['determineHeatLostGain'] &&
          generateAdditionalInfo(
            t('stepPage.additionalInformation.label'),
            project.additionalInformation['determineHeatLostGain']
          )}
      </div>
    );
  };
  const generateStandardStep = (step) => {
    const stepNumber = step.number;
    const stepId = step.id;
    const selectedValue =
      projectSteps &&
      projectSteps[stepId] &&
      projectSteps[stepId][`${stepId}-value`];
    const stepIsCompleted = project.completedSteps.some((s) => s === stepId);
    return (
      <div className="print-step">
        <hr />
        <h4>
          {stepIsCompleted && (
            <img
              alt=""
              className="completed-step"
              src={'/assets/images/CompletedStep.png'}
            />
          )}
          {`${t('checklist.stepNumber', {
            stepNumber: stepNumber,
          })} - ${t(`step-${stepId}:title`)}`}
        </h4>
        {projectSteps && projectSteps[stepId] && selectedValue && (
          <>
            <label>{t('checklist.selectedOption')}</label>
            <p>{`${stepNumber}${selectedValue} -
              ${t(
                `step-${stepId}:form.${selectedValue.toLowerCase()}.option.text`
              )}
              `}</p>
          </>
        )}

        {projectSteps &&
          projectSteps[StepsList[3].id] &&
          projectSteps[StepsList[3].id]?.zoningApproachDescription &&
          stepId === StepsList[3].id && (
            <>
              <label>
                {t(
                  `step-${StepsList[3].id}:form.c.field.zoningApproachDescription.label`
                )}
              </label>
              <p>{projectSteps[StepsList[3].id].zoningApproachDescription}</p>
            </>
          )}
        {projectSteps &&
          projectSteps[StepsList[8].id] &&
          projectSteps[StepsList[8].id].tonnage &&
          stepId === StepsList[8].id && (
            <>
              <label>{t(`step-${StepsList[8].id}:form.tonnage.label`)}</label>
              <p>{projectSteps[StepsList[8].id].tonnage}</p>
            </>
          )}
        {projectSteps &&
          projectSteps[StepsList[12].id] &&
          projectSteps[StepsList[12].id].heatingTechDescription &&
          stepId === StepsList[12].id && (
            <>
              <label>
                {t(
                  `step-${StepsList[12].id}:form.c.form.heatingTechDescription`
                )}
              </label>
              <p>{projectSteps[StepsList[12].id].heatingTechDescription}</p>
            </>
          )}
        {projectSteps &&
          projectSteps[StepsList[12].id] &&
          projectSteps[StepsList[12].id].ratedSpaceOutput &&
          stepId === StepsList[12].id && (
            <>
              <label>
                {t(`step-${StepsList[12].id}:form.c.form.ratedSpaceOutput`)}
              </label>
              <p>{projectSteps[StepsList[12].id].ratedSpaceOutput}</p>
            </>
          )}
        {projectSteps &&
          projectSteps[StepsList[12].id] &&
          projectSteps[StepsList[12].id].zoningDampersRequired &&
          stepId === StepsList[12].id && (
            <>
              <label>
                {t(
                  `step-${StepsList[12].id}:form.c.form.zoningDampersRequired`
                )}
              </label>
              <p>{projectSteps[StepsList[12].id].zoningDampersRequired}</p>
            </>
          )}
        {projectSteps &&
          projectSteps[StepsList[12].id] &&
          projectSteps[StepsList[12].id].ratedDhwCapacity &&
          stepId === StepsList[12].id && (
            <>
              <label>
                {t(`step-${StepsList[12].id}:form.c.form.ratedDhwCapacity`)}
              </label>
              <p>{projectSteps[StepsList[12].id].ratedDhwCapacity}</p>
            </>
          )}
        {project.additionalInformation &&
          project.additionalInformation[stepId] &&
          generateAdditionalInfo(
            t('stepPage.additionalInformation.label'),
            project.additionalInformation[stepId]
          )}
      </div>
    );
  };
  return (
    <div ref={reference} className="checklist-print-format">
      {generateHeader()}
      <section>{generateChecklistFieldInfo()}</section>
      <section>
        <h3>{t('checklist.accordianTitle1')}</h3>
        {generateStep1Info()}
        {generateStandardStep(StepsList[1])}
        {generateStandardStep(StepsList[2])}
        {generateStandardStep(StepsList[3])}
        {generateStandardStep(StepsList[4])}
        {generateStandardStep(StepsList[5])}
        {generateStandardStep(StepsList[6])}
        <hr />
      </section>
      <section>
        <h3>{t('checklist.accordianTitle2')}</h3>
        {generateStep8Info()}
        {generateStandardStep(StepsList[8])}
        <hr />
      </section>
      <section>
        <h3>{t('checklist.accordianTitle3')}</h3>
        {generateStandardStep(StepsList[9])}
        {generateStandardStep(StepsList[10])}
        {generateStandardStep(StepsList[11])}
        {generateStandardStep(StepsList[12])}
        <hr />
      </section>
    </div>
  );
};

export default ChecklistPrintFormat;
