import { env } from './env';

const adB2cOrgName = env.REACT_APP_AD_B2C_ORGANIZATION_NAME;
const adB2cAuthority = `${adB2cOrgName}.b2clogin.com`;
const adB2cDomain = `${adB2cOrgName}.onmicrosoft.com`;
const accountDeletePolicyName = 'B2C_1A_AccountDelete';
const passwordChangePolicyName = 'B2C_1A_PasswordChange';
const profileEditPolicyName = 'B2C_1A_ProfileEdit';
const signUpPolicyName = 'B2C_1A_signup';
const signUpSignInPolicyName = 'B2C_1A_signup_signin';

export const b2cPolicies = {
  names: {
    passwordChange: passwordChangePolicyName,
    signUp: signUpPolicyName,
    signUpSignIn: signUpSignInPolicyName,
  },
  authorities: {
    accountDelete: {
      authority: `https://${adB2cAuthority}/${adB2cDomain}/${accountDeletePolicyName}`,
    },
    passwordChange: {
      authority: `https://${adB2cAuthority}/${adB2cDomain}/${passwordChangePolicyName}`,
    },
    profileEdit: {
      authority: `https://${adB2cAuthority}/${adB2cDomain}/${profileEditPolicyName}`,
    },
    signUp: {
      authority: `https://${adB2cAuthority}/${adB2cDomain}/${signUpPolicyName}`,
    },
    signUpSignIn: {
      authority: `https://${adB2cAuthority}/${adB2cDomain}/${signUpSignInPolicyName}`,
    },
  },
  authorityDomain: adB2cAuthority,
};
export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_AD_B2C_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    validateUri: false,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const protectedResources = {
  projectsApi: {
    endpoint: env.REACT_APP_API_URL,
    scopes: [
      `https://${adB2cDomain}/${env.REACT_APP_AD_B2C_API_CLIENT_ID}/projects.write`,
    ],
  },
};
