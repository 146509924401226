export enum AppRoute {
  CreateProject = 'routes:createProject',
  CreateProjectDetails = 'routes:createProjectDetails',
  CreateProjectIdentification = 'routes:createProjectIdentification',
  CreateProjectSettings = 'routes:createProjectSettings',
  Dashboard = 'routes:dashboard',
  Home = 'routes:home',
  License = 'routes:license',
  Logout = 'routes:logout',
  NotFound = 'routes:notFound',
  Project = 'routes:project',
  ProjectChecklist = 'routes:projectChecklist',
  ProjectOverview = 'routes:projectOverview',
  ProjectPath = 'routes:projectPath',
  ProjectSettings = 'routes:projectSettings',
  Step = 'routes:step',
  StepPath = 'routes:stepPath',
  Step1 = 'routes:step1',
  Step2 = 'routes:step2',
  Step3 = 'routes:step3',
  Step4 = 'routes:step4',
  Step5 = 'routes:step5',
  Step6 = 'routes:step6',
  Step7 = 'routes:step7',
  Step8 = 'routes:step8',
  Step9 = 'routes:step9',
  Step10 = 'routes:step10',
  Step11 = 'routes:step11',
  Step12 = 'routes:step12',
  Step13 = 'routes:step13',
  Videos = 'routes:videos',
}
