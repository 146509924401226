import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useScript } from '../../hooks/useScript';
import GCFooter from './GCFooter';
import GCPreFooter from './GCPreFooter';
import GCTop from './GCTop';

const GCWetBoewLoader: React.FC = ({ children }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [init, setInit] = useState<boolean>(false);
  const wetBoewScript = useScript(
    '/etc/designs/canada/cdts/gcweb/wet-boew/js/wet-boew.min.js'
  );

  useEffect(() => {
    if (wetBoewScript === 'ready' && (window as any).wb) {
      setInit(true);
    }
  }, [wetBoewScript]);

  return (
    <>
      <Helmet>
        <html className="no-js" lang={language} dir="ltr" />
        <title>{t('pageTitle.appName')}</title>
        {init && (
          <script
            type="text/javascript"
            src="/etc/designs/canada/cdts/gcweb/release/js/theme.min.js"
          />
        )}
      </Helmet>

      <GCTop />

      <main id="wb-cont" property="mainContentOfPage" typeof="WebPageElement">
        <Grid>
          <Row>
            <Col xs={12}>
              {children}

              <GCPreFooter />
            </Col>
          </Row>
        </Grid>
      </main>

      <GCFooter />
    </>
  );
};

export default GCWetBoewLoader;
