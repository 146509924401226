import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Well } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BigNumber } from 'bignumber.js';
import { Steps } from '../../../model/Step';

interface Props {
  steps?: { [key: string]: any };
  stepId: string;
}

const ProjectStepWarning: React.FC<Props> = ({ steps, stepId }) => {
  const { t } = useTranslation();

  const warnings = useMemo(() => {
    if (!steps) return [];

    const dhlValue =
      (steps[Steps.STEP_8] &&
        !!steps[Steps.STEP_8].dhl &&
        new BigNumber(steps[Steps.STEP_8].dhl)) ||
      undefined;
    const dhgValue =
      (steps[Steps.STEP_8] &&
        !!steps[Steps.STEP_8].dhg &&
        new BigNumber(steps[Steps.STEP_8].dhg)) ||
      undefined;
    const ratedSpaceHeatingOutput =
      (steps[Steps.STEP_13] &&
        !!steps[Steps.STEP_13].ratedSpaceOutput &&
        new BigNumber(steps[Steps.STEP_13].ratedSpaceOutput)) ||
      undefined;
    const getHoValue = () => {
      if (dhlValue && ratedSpaceHeatingOutput) {
        return BigNumber.maximum(dhlValue, ratedSpaceHeatingOutput);
      }
      if (dhlValue) {
        return dhlValue;
      }
      if (ratedSpaceHeatingOutput) {
        return ratedSpaceHeatingOutput;
      }
      return undefined;
    };
    const hoValue = getHoValue();
    const hcfmValue =
      (hoValue && hoValue.dividedBy(1.08).dividedBy(45)) || undefined;
    const tonnage =
      (steps[Steps.STEP_9] &&
        !!steps[Steps.STEP_9].tonnage &&
        new BigNumber(steps[Steps.STEP_9].tonnage)) ||
      undefined;
    const getCoTonValue = () => {
      const step9Value =
        (steps[Steps.STEP_9] && steps[Steps.STEP_9][`${Steps.STEP_9}-value`]) ||
        undefined;
      if (step9Value === 'A') {
        return new BigNumber(1);
      }
      if (step9Value === 'B') {
        return new BigNumber(1.5);
      }
      if (step9Value === 'C' && tonnage) {
        return new BigNumber(tonnage);
      }
      return undefined;
    };
    const coTonValue = getCoTonValue();
    const ccfmValue = (coTonValue && coTonValue.multipliedBy(400)) || undefined;

    const step11Value =
      (steps[Steps.STEP_11] &&
        steps[Steps.STEP_11][`${Steps.STEP_11}-value`]) ||
      undefined;

    const step12Value =
      (steps[Steps.STEP_12] &&
        steps[Steps.STEP_12][`${Steps.STEP_12}-value`]) ||
      undefined;

    const step13Value =
      (steps[Steps.STEP_13] &&
        steps[Steps.STEP_13][`${Steps.STEP_13}-value`]) ||
      undefined;

    const tonnageBtuPerHour =
      (coTonValue && coTonValue.multipliedBy(12000)) || undefined;
    const lowDhgRange = 0.8 * dhgValue;
    const highDhgRange = 1.25 * dhgValue;

    const warningMsgs: string[] = [];

    if (stepId === Steps.STEP_8 && !dhlValue) {
      warningMsgs.push(t('flags.flag1'));
    }

    if (
      (stepId === Steps.STEP_8 ||
        stepId === Steps.STEP_9 ||
        stepId === Steps.STEP_13) &&
      hcfmValue &&
      ccfmValue &&
      ccfmValue.minus(hcfmValue).isGreaterThan(200)
    ) {
      warningMsgs.push(t('flags.flag2'));
    }

    if (
      (stepId === Steps.STEP_11 || stepId === Steps.STEP_12) &&
      (step11Value === 'B' || step11Value === 'C') &&
      step12Value === 'A'
    ) {
      warningMsgs.push(t('flags.flag3'));
    }

    if (
      ((stepId === Steps.STEP_8 || stepId === Steps.STEP_9) &&
        tonnageBtuPerHour &&
        tonnageBtuPerHour.isLessThan(lowDhgRange)) ||
      (tonnageBtuPerHour && tonnageBtuPerHour.isGreaterThan(highDhgRange))
    ) {
      warningMsgs.push(t('flags.flag4'));
    }

    if (stepId === Steps.STEP_13 && step13Value && step13Value === 'B') {
      warningMsgs.push(t('flags.flag5'));
    }

    return warningMsgs;
  }, [stepId, steps, t]);

  return (
    <>
      {warnings.length > 0 &&
        warnings.map((warning, i) => {
          return (
            <Well key={i} className="well-dark project-step-warning">
              <h3 className="project-step-warning-header">
                <FontAwesomeIcon icon={faFlag} /> Important
              </h3>
              {t(warning)}
            </Well>
          );
        })}
    </>
  );
};

export default ProjectStepWarning;
