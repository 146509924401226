import React from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';

import { useMsal } from '@azure/msal-react';
import {
  AppHeader,
  LicensePage,
  ProtectedRoute,
  ScrollToTop,
  TermsAndConditionsModal,
} from './components/common';
import { CreateProjectPage } from './components/CreateProject';

import { AppRoute } from './const';
import { Dashboard } from './components/Dashboard';
import { LocalizedNavLink, LocalizedSwitch } from './components/i18n';
import { Logout } from './components/Login';
import LoginPage from './components/Login/LoginPage';
import { PageNotFound } from './components/NotFound';
import { ProjectPage } from './components/Project';
import { VideosPage } from './components/Videos';

import './styles/App.scss';

const App: React.FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { instance } = useMsal();

  moment.locale(language);

  instance
    .handleRedirectPromise()
    .then((authResult) => {
      if (authResult !== null) {
        if (authResult.idTokenClaims['accountIsDeleted']) {
          instance.logout();
        } else {
          instance.setActiveAccount(authResult.account);
        }
      }
    })
    .catch((error) => console.error(error));

  const onIdle = () => {
    if (instance.getActiveAccount()) {
      instance.logoutRedirect({
        postLogoutRedirectUri: `${window.location.pathname.substring(0, 4)}?${t(
          'logoutReason.queryKey'
        )}=${t('logoutReason.inactive.queryValue')}`,
      });
    }
  };

  // Idle after 30 minutes of inactivity
  const idleTimer = useIdleTimer({ timeout: 1800000, onIdle });
  idleTimer.start();

  return (
    <div id="main-app-content">
      <ScrollToTop>
        <LocalizedSwitch>
          <Route exact path={AppRoute.Home}>
            <LoginPage />
          </Route>

          <Route exact path={AppRoute.Logout}>
            <Logout />
          </Route>

          <Route path={AppRoute.NotFound}>
            <PageNotFound />
          </Route>

          <Route path={AppRoute.ProjectPath}>
            <ProjectPage />
          </Route>

          <Route path={AppRoute.License}>
            <LicensePage />
          </Route>

          <Route path={AppRoute.Videos}>
            <VideosPage />
          </Route>

          <ProtectedRoute>
            <AppHeader />
            <LocalizedSwitch>
              <Route exact path={AppRoute.Dashboard}>
                <Dashboard />
              </Route>

              <Route path={t(AppRoute.CreateProject)}>
                <CreateProjectPage />
              </Route>

              <Route exact path={'*'}>
                <Redirect to={`/${t(AppRoute.NotFound)}`} />
              </Route>
            </LocalizedSwitch>
          </ProtectedRoute>
        </LocalizedSwitch>
      </ScrollToTop>
      <div className="license-section">
        <a href={t('termsAndConditions.url')} lang={language}>
          {t('termsAndConditions.link')}
        </a>
        |
        <LocalizedNavLink to={AppRoute.License}>
          {t('license.link')}
        </LocalizedNavLink>
      </div>
      <TermsAndConditionsModal />
    </div>
  );
};

export default App;
